import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import currencyFormat from '@/core/filters/currency-format.filter';
import constantsDescription from '@/core/filters/constants-description.filter';
import { DeliveryMethod, VehicleType } from '@/core/constants/enums';
import { i18nHelper } from '@/core/utils';
import { ROUTE_NAME } from '@/core/constants';
export const productService = {
  productDataMapping
};

function productDataMapping(data, index) {
  let d = data;
  let subRouterName = null;
  let filterCategoryName = null;
  let extra = d.extra ? d.extra : '';
  switch (d.filter_category) {
    case 'ALL':
      filterCategoryName = 'ALL';
      subRouterName = ROUTE_NAME.PRE_ORDER_FOOD_ALL;
      break;
    case 'TRENDING':
      filterCategoryName = 'TRENDING';
      subRouterName = ROUTE_NAME.PRE_ORDER_FOOD_TRENDING;
      break;
    case 'SOMETHING_SWEET':
      filterCategoryName = 'SOMETHING SWEET';
      subRouterName = ROUTE_NAME.PRE_ORDER_FOOD_SOMETHING_SWEET;
      break;
    case 'ASIA':
      filterCategoryName = 'ASIA';
      subRouterName = ROUTE_NAME.PRE_ORDER_FOOD_ASIA;
      break;
    case 'WESTERN':
      filterCategoryName = 'WESTERN';
      subRouterName = ROUTE_NAME.PRE_ORDER_FOOD_WESTERN;
  }

  function assertSortByOrder(a, b) {
    if (a.order > b.order) return 1;
    if (a.order < b.order) return -1;
  }

  return {
    no: ['', null, undefined].includes(index) ? null : index + 1,
    id: d.id,
    uuid: d.uuid,
    name: d.name,
    description: d.description,
    // hashtag: d.hashtag ? d.hashtag.split(',') : '-',
    variation: d.variation.map((x, index) => {
      return {
        no: index + 1,
        name: x.name,
        uuid: x.uuid,
        price: currencyFormat(x.price),
        isActive: x.is_active
      };
    }),
    addOn: d.add_on
      ? d.add_on.map((x, index) => {
          return {
            no: index + 1,
            name: x.name,
            uuid: x.uuid,
            price: currencyFormat(x.price),
            quantity: x.quantity ? x.quantity : '-',
            isActive: x.is_active
          };
        })
      : [],
    availableFrom: dateTimeFormat(d.available_from),
    availableTo: dateTimeFormat(d.available_to),
    recipeId: d.recipe_id,
    userId: d.user_id,
    deliveryMethod: getDeliveryMethod(d.delivery_method),
    createdAt: dateTimeFormat(d.created_at),
    updatedAt: dateTimeFormat(d.updated_at),
    isActive: d.is_active,
    categoryId: d.category_id,
    cuisineType: constantsDescription('cuisineType', d.cuisine_type),
    foodCategory: constantsDescription('foodCategory', d.food_category),
    foodType: constantsDescription('foodType', extra.food_type),
    filterType: (
      constantsDescription('cuisineType', d.cuisine_type) +
      ',' +
      constantsDescription('filterType', d.filter_type)
    ).split(','),
    isPopular: Boolean(d.is_popular),
    isTrending: Boolean(d.is_trending),
    isTop10: Boolean(d.is_top10),
    isRecommended: Boolean(d.is_recommended),
    isRated: Boolean(d.is_rated),
    price: d.price,
    sellingQuantity: `${constantsDescription('limitType', d.limit_type)}${
      d.quantity != null ? ',' + Number(d.quantity) : ''
    }`,
    deletedAt: dateTimeFormat(d.deleted_at),
    isVisible: Boolean(d.is_visible),
    filterCategory: d.filter_category,
    filterCategoryName: filterCategoryName,
    reviews: d.reviews
      ? d.reviews.map((y) => {
          return {
            id: y.id,
            productId: y.product_id,
            userId: y.user_id,
            rating: y.rating,
            description: y.description,
            createdAt: y.created_at,
            updatedAt: y.updated_at,
            isVisible: y.is_visible
          };
        })
      : null,
    recipeName: d.recipe_name,
    username: d.username,
    averageRating: d.average_rating
      ? Number(d.average_rating.toString().substr(0, 4))
      : 0,
    recipe: {
      id: d.recipe_id,
      name: d.recipe_name
    },
    categoryName: d.category_name ? d.category_name : '-',
    assets: d.assets
      .map((x) => {
        return {
          id: x.id,
          mediaPath: x.media_path,
          mediaType: x.media_type,
          timer: x.timer,
          order: x.order
        };
      })
      .sort(assertSortByOrder),
    image: d.image,
    thumbnail: d.thumbnail,
    vehicleType: getVehicleType(d.delivery_method),
    subRouterName: subRouterName
  };
}

function getDeliveryMethod(data) {
  var deliveryMethod;

  if (data)
    deliveryMethod = data.filter((x) => x != DeliveryMethod.DELIVERY_BY_CAR);

  deliveryMethod = constantsDescription('deliveryMethod', deliveryMethod);

  return deliveryMethod;
}

function getVehicleType(data) {
  var vehicle = null;
  var isDelivery = false;

  data.forEach((x) => {
    if (x == DeliveryMethod.PRE_ORDER) {
      isDelivery = true;
    }
  });

  if (isDelivery) {
    data.forEach((x) => {
      if ([VehicleType.CAR].includes(x)) {
        vehicle = i18nHelper.getMessage(`enumVehicleType.${x}`);
      }
    });

    if (!vehicle) {
      vehicle = i18nHelper.getMessage(
        `enumVehicleType.${VehicleType.ANY_VEHICLE}`
      );
    }
  } else {
    vehicle = '-';
  }

  return vehicle;
}
