const PERMISSION_CONSTANTS = Object.freeze({
  USER_PERMISSIONS: 'userPermissions'
});

const MODULE = Object.freeze({
  // MEMBER
  USER: 'user',
  CREATOR_APPLICATION: 'creator-application',
  SUBSCRIPTION: 'subscription',

  // MERCHANT
  MERCHANT_GROUPING: 'merchant-grouping',
  OFFLINE_MERCHANT: 'offline-merchant',
  POPULAR_SEARCH: 'popular-search',

  // PURCHASE
  ECOPACKK: 'eco-pack',
  EQUIPMENTS: 'equipments',
  ORDERS: 'orders',

  // POST
  RECIPE: 'recipe',
  PRODUCT: 'product',
  RECIPE_REVIEW: 'recipe-review',
  PRODUCT_REVIEW: 'product-review',

  // COMPANY WEBSITE
  STUDIO: 'studio',
  RECIPE_PAGE: 'recipe-page',

  // MOBILE APPLICATION
  MOBILE_APPLICATION: 'mobile-application',

  // PROMOTION
  PROMOTION: 'promotion',
  REFERRAL_CODE: 'referral-code',
  VOUCHER: 'merchant-voucher',

  // TRANSACTIONS
  FINANCE_REPORTS: 'finance-reports',

  // CONTENT MANAGEMENT
  BANNER: 'banners',
  BANNER_HOME: 'banner-home',
  PLATFORM_INFO: 'platform-info',
  HOME_PAGE_BUTTON: 'home-page-button',

  // ADMIN USER
  ADMIN_USER: 'admin-user',
  ROLE: 'role',

  // SETTINGS
  MAINTENANCE_SCHEDULE: 'maintenance-schedule',
  APP_INFO: 'app-info',
  PLATFORM_OPERATION_HOUR: 'platform-operation-hour',
  OFF_DAY_SETTING: 'custom-offday',
  CONFIGURATION: 'configuration',

  // OTHER
  AUDIT_LOG: 'audit-log',
  XSTREET: 'xstreet'
});

const ACTION = Object.freeze({
  /* Admin Users */
  ADMIN_USER_CREATE: 'admin-user.create',
  ADMIN_USER_READ: 'admin-user.read',
  ADMIN_USER_UPDATE: 'admin-user.update',
  ADMIN_USER_DELETE: 'admin-user.delete',

  /* Roles */
  ROLE_CREATE: 'role.create',
  ROLE_READ: 'role.read',
  ROLE_UPDATE: 'role.update',
  ROLE_DELETE: 'role.delete',

  /* Recipe */
  RECIPE_CREATE: 'recipe.create',
  RECIPE_READ: 'recipe.read',
  RECIPE_UPDATE: 'recipe.update',
  RECIPE_DELETE: 'recipe.delete',

  /* Users */
  USER_CREATE: 'user.create',
  USER_READ: 'user.read',
  USER_UPDATE: 'user.update',
  USER_DELETE: 'user.delete',

  /* Users */
  CREATOR_APPLICATION_CREATE: 'creator-application.create',
  CREATOR_APPLICATION_READ: 'creator-application.read',
  CREATOR_APPLICATION_UPDATE: 'creator-application.update',
  CREATOR_APPLICATION_DELETE: 'creator-application.delete',

  /* Banners*/
  BANNER_CREATE: 'banners.create',
  BANNER_READ: 'banners.read',
  BANNER_UPDATE: 'banners.update',
  BANNER_DELETE: 'banners.delete',

  /* Banners home*/
  // BANNER_CREATE: 'banner-home.create',
  // BANNER_READ: 'banner-home.read',
  // BANNER_UPDATE: 'banner-home.update',
  // BANNER_DELETE: 'banner-home.delete',

  /* Audit Log */
  AUDIT_LOG_CREATE: 'audit-log.create',
  AUDIT_LOG_READ: 'audit-log.read',
  AUDIT_LOG_UPDATE: 'audit-log.update',
  AUDIT_LOG_DELETE: 'audit-log.delete',

  /* XStreet */
  XSTREET_CREATE: 'xstreet.create',
  XSTREET_READ: 'xstreet.read',
  XSTREET_UPDATE: 'xstreet.update',
  XSTREET_DELETE: 'xstreet.delete',

  /* Product */
  PRODUCT_CREATE: 'product.create',
  PRODUCT_READ: 'product.read',
  PRODUCT_UPDATE: 'product.update',
  PRODUCT_DELETE: 'product.delete',

  /* Platform Info */
  PLATFORM_INFO_READ: 'platform-info.read',
  PLATFORM_INFO_UPDATE: 'platform-info.update',

  /* Recipe Review */
  RECIPE_REVIEW_CREATE: 'recipe-review.create',
  RECIPE_REVIEW_READ: 'recipe-review.read',
  RECIPE_REVIEW_UPDATE: 'recipe-review.update',
  RECIPE_REVIEW_DELETE: 'recipe-review.delete',

  /* Product Review */
  PRODUCT_REVIEW_CREATE: 'product-review.create',
  PRODUCT_REVIEW_READ: 'product-review.read',
  PRODUCT_REVIEW_UPDATE: 'product-review.update',
  PRODUCT_REVIEW_DELETE: 'product-review.delete',

  /* Maintenance Schedule */
  MAINTENANCE_SCHEDULE_CREATE: 'maintenance-schedule.create',
  MAINTENANCE_SCHEDULE_READ: 'maintenance-schedule.read',
  MAINTENANCE_SCHEDULE_UPDATE: 'maintenance-schedule.update',
  MAINTENANCE_SCHEDULE_DELETE: 'maintenance-schedule.delete',

  /* App Info */
  APP_INFO_CREATE: 'app-info.create',
  APP_INFO_READ: 'app-info.read',
  APP_INFO_UPDATE: 'app-info.update',
  APP_INFO_DELETE: 'app-info.delete',

  /* Platform Operation Hour*/
  PLATFORM_OPERATION_HOUR_CREATE: 'platform-operation-hour.create',
  PLATFORM_OPERATION_HOUR_READ: 'platform-operation-hour.read',
  PLATFORM_OPERATION_HOUR_UPDATE: 'platform-operation-hour.update',
  PLATFORM_OPERATION_HOUR_DELETE: 'platform-operation-hour.delete',

  /* Orders */
  ORDERS_READ: 'orders.read',
  ORDERS_UPDATE: 'orders.update',

  /* Orders */
  SUBSCRIPTION_READ: 'subscription.read',
  SUBSCRIPTION_UPDATE: 'subscription.update',

  /* Eco-Pack */
  ECO_PACK_READ: 'eco-pack.read',
  ECO_PACK_UPDATE: 'eco-pack.update',

  /* Promotion */
  PROMOTION_READ: 'promotion.read',
  PROMOTION_UPDATE: 'promotion.update',

  /* Reports */
  FINANCE_REPORTS_READ: 'finance-reports.read',
  FINANCE_REPORTS_CREATE: 'finance-reports.create',
  FINANCE_REPORTS_UPDATE: 'finance-reports.update',
  FINANCE_REPORTS_DELETE: 'finance-reports.delete',

  /* Referral Code*/
  REFERRAL_CODE_READ: 'referral-code.read',
  REFERRAL_CODE_CREATE: 'referral-code.create',
  REFERRAL_CODE_UPDATE: 'referral-code.update',
  REFERRAL_CODE_DELETE: 'referral-code.delete',

  /*Voucher*/
  VOUCHER_READ: 'merchant-voucher.read',
  VOUCHER_CREATE: 'merchant-voucher.create',
  VOUCHER_UPDATE: 'merchant-voucher.update',
  VOUCHER_DELETE: 'merchant-voucher.delete',

  /*Offline Merchant*/
  OFFLINE_MERCHANT_READ: 'offline-merchant.read',
  OFFLINE_MERCHANT_CREATE: 'offline-merchant.create',
  OFFLINE_MERCHANT_UPDATE: 'offline-merchant.update',
  OFFLINE_MERCHANT_DELETE: 'offline-merchant.delete',

  /* Studio */
  STUDIO_READ: 'studio.read',
  STUDIO_CREATE: 'studio.create',
  STUDIO_UPDATE: 'studio.update',
  STUDIO_DELETE: 'studio.delete',

  /* configuration */
  CONFIGURATION_READ: 'configuration.read',
  CONFIGURATION_CREATE: 'configuration.create',
  CONFIGURATION_UPDATE: 'configuration.update',
  CONFIGURATION_DELETE: 'configuration.delete',

  /* equipment */
  EQUIPMENTS_READ: 'equipments.read',
  EQUIPMENTS_CREATE: 'equipments.create',
  EQUIPMENTS_UPDATE: 'equipments.update',
  EQUIPMENTS_DELETE: 'equipments.delete',

  /*Popular Search*/
  POPULAR_SEARCH_READ: 'popular-search.read',
  POPULAR_SEARCH_CREATE: 'popular-search.create',
  POPULAR_SEARCH_UPDATE: 'popular-search.update',
  POPULAR_SEARCH_DELETE: 'popular-search.delete',

  /*Recipe Page*/
  RECIPE_PAGE_READ: 'recipe-page.read',
  RECIPE_PAGE_CREATE: 'recipe-page.create',
  RECIPE_PAGE_UPDATE: 'recipe-page.update',
  RECIPE_PAGE_DELETE: 'recipe-page.delete',

  /*Off Day Setting*/
  OFF_DAY_SETTING_READ: 'custom-offday.read',
  OFF_DAY_SETTING_CREATE: 'custom-offday.create',
  OFF_DAY_SETTING_UPDATE: 'custom-offday.update',
  OFF_DAY_SETTING_DELETE: 'custom-offday.delete',

  /*Merchant Grouping*/
  MERCHANT_GROUPING_READ: 'merchant-grouping.read',
  MERCHANT_GROUPING_CREATE: 'merchant-grouping.create',
  MERCHANT_GROUPING_UPDATE: 'merchant-grouping.update',
  MERCHANT_GROUPING_DELETE: 'merchant-grouping.delete',

  /*Mobile Application*/
  MOBILE_APPLICATION_READ: 'mobile-application.read',
  MOBILE_APPLICATION_UPDATE: 'mobile-application.update'
});

export { PERMISSION_CONSTANTS, MODULE, ACTION };
