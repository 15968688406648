import { GLOBAL, MODULE, ACTION, ROUTE_NAME } from '@/core/constants';
/**
 __   __               ____       _       _   _     _     
 \ \ / /__  _   _     / ___| ___ | |_    | |_| |__ (_)___ 
  \ V / _ \| | | |   | |  _ / _ \| __|   | __| '_ \| / __|
   | | (_) | |_| |   | |_| | (_) | |_    | |_| | | | \__ \
   |_|\___/ \__,_|    \____|\___/_\__|    \__|_| |_|_|___/
      / ___| ___   ___   __| |  | |_   _  ___| | __       
     | |  _ / _ \ / _ \ / _` |  | | | | |/ __| |/ /       
     | |_| | (_) | (_) | (_| |  | | |_| | (__|   <        
      \____|\___/ \___/ \__,_|  |_|\__,_|\___|_|\_\       
                                                          
*/
const routes = [
  // Login ::start
  {
    path: '/login',
    name: ROUTE_NAME.LOGIN,
    component: () => import('@/views/pages/auth/Login'),
    meta: {
      layout: GLOBAL.LAYOUT_BLANK,
      requiresAuth: false
    },
    children: []
  },
  // Login ::end

  // Home ::start
  {
    path: '/home',
    name: ROUTE_NAME.HOME,
    component: () => import('@/views/pages/Home.vue'),
    meta: {
      name: 'home',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: []
    },
    children: []
  },
  // Home ::end

  // Account Management ::start
  {
    path: '/account-management',
    name: ROUTE_NAME.ACCOUNT_MANAGEMENT,
    redirect: { name: ROUTE_NAME.ACCOUNT_MANAGEMENT_LIST },
    component: () =>
      import('@/views/pages/account-management/AccountManagement.vue'),
    meta: {
      name: 'accountManagement',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.ADMIN_USER]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.ACCOUNT_MANAGEMENT_LIST,
        component: () =>
          import('@/views/pages/account-management/AccountManagementList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ADMIN_USER],
          authorizeActions: [ACTION.ADMIN_USER_READ]
        },
        children: []
      },
      {
        path: 'new',
        name: ROUTE_NAME.ACCOUNT_MANAGEMENT_NEW,
        component: () =>
          import('@/views/pages/account-management/AccountManagementNew.vue'),
        meta: {
          name: 'new',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ADMIN_USER],
          authorizeActions: [ACTION.ADMIN_USER_CREATE]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.ACCOUNT_MANAGEMENT_DETAILS,
        component: () =>
          import(
            '@/views/pages/account-management/AccountManagementDetails.vue'
          ),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ADMIN_USER],
          authorizeActions: [
            ACTION.ADMIN_USER_READ,
            ACTION.ADMIN_USER_UPDATE,
            ACTION.ADMIN_USER_DELETE
          ]
        },
        children: []
      }
    ]
  },
  // Account Management ::end

  // Role Permission ::start
  {
    path: '/role-permission',
    name: ROUTE_NAME.ROLE_PERMISSION,
    redirect: { name: ROUTE_NAME.ROLE_PERMISSION_LIST },
    component: () => import('@/views/pages/role-permission/RolePermission.vue'),
    meta: {
      name: 'role',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.ROLE]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.ROLE_PERMISSION_LIST,
        component: () =>
          import('@/views/pages/role-permission/RolePermissionList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ROLE],
          authorizeActions: [ACTION.ROLE_READ]
        },
        children: []
      },
      {
        path: 'new',
        name: ROUTE_NAME.ROLE_PERMISSION_NEW,
        component: () =>
          import('@/views/pages/role-permission/RolePermissionNew.vue'),
        meta: {
          name: 'new',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ROLE],
          authorizeActions: [ACTION.ROLE_CREATE]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.ROLE_PERMISSION_DETAILS,
        component: () =>
          import('@/views/pages/role-permission/RolePermissionDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ROLE],
          authorizeActions: [
            ACTION.ROLE_READ,
            ACTION.ROLE_UPDATE,
            ACTION.ROLE_DELETE
          ]
        },
        children: []
      }
    ]
  },
  // Role Permission ::end

  // Profile Details ::start
  {
    path: '/profile-details',
    name: ROUTE_NAME.PROFILE_DETAILS,
    redirect: { name: ROUTE_NAME.PROFILE_DETAILS_LIST },
    component: () => import('@/views/pages/profile-details/ProfileDetails.vue'),
    meta: {
      name: 'user',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.USER]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.PROFILE_DETAILS_LIST,
        component: () =>
          import('@/views/pages/profile-details/ProfileDetailsList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.USER],
          authorizeActions: [ACTION.USER_READ, ACTION.USER_UPDATE]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.PROFILE_DETAILS_DETAILS,
        component: () =>
          import('@/views/pages/profile-details/ProfileDetailsDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.USER],
          authorizeActions: [ACTION.USER_READ, ACTION.USER_UPDATE]
        },
        children: []
      }
    ]
  },
  // Profile Details ::end

  // Application ::start
  {
    path: '/application',
    name: ROUTE_NAME.APPLICATION,
    redirect: { name: ROUTE_NAME.APPLICATION_LIST },
    component: () => import('@/views/pages/application/Application.vue'),
    meta: {
      name: 'application',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.CREATOR_APPLICATION]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.APPLICATION_LIST,
        component: () =>
          import('@/views/pages/application/ApplicationList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.CREATOR_APPLICATION],
          authorizeActions: [
            ACTION.CREATOR_APPLICATION_READ,
            ACTION.CREATOR_APPLICATION_UPDATE
          ]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.APPLICATION_DETAILS,
        component: () =>
          import('@/views/pages/application/ApplicationDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.CREATOR_APPLICATION],
          authorizeActions: [
            ACTION.CREATOR_APPLICATION_READ,
            ACTION.CREATOR_APPLICATION_UPDATE
          ]
        },
        children: []
      }
    ]
  },
  // Application ::end

  // Subscription ::start
  {
    path: '/newsletter-subscribers',
    name: ROUTE_NAME.SUBSCRIPTION,
    redirect: { name: ROUTE_NAME.SUBSCRIPTION_LIST },
    component: () => import('@/views/pages/subscription/Subscription.vue'),
    meta: {
      name: 'subscription',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.CREATOR_APPLICATION]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.SUBSCRIPTION_LIST,
        component: () =>
          import('@/views/pages/subscription/SubscriptionList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.CREATOR_APPLICATION],
          authorizeActions: [
            ACTION.CREATOR_APPLICATION_READ,
            ACTION.CREATOR_APPLICATION_UPDATE
          ]
        },
        children: []
      }
    ]
  },
  // Subscription ::end

  // Offline Merchant ::start
  {
    path: '/offline-merchant',
    name: ROUTE_NAME.OFFLINE_MERCHANT,
    redirect: { name: ROUTE_NAME.OFFLINE_MERCHANT_LIST },
    component: () =>
      import('@/views/pages/offline-merchant/OfflineMerchant.vue'),
    meta: {
      name: 'offlineMerchant',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.CREATOR_APPLICATION]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.OFFLINE_MERCHANT_LIST,
        component: () =>
          import('@/views/pages/offline-merchant/OfflineMerchantList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.CREATOR_APPLICATION],
          authorizeActions: [
            ACTION.CREATOR_APPLICATION_READ,
            ACTION.CREATOR_APPLICATION_UPDATE
          ]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.OFFLINE_MERCHANT_DETAILS,
        component: () =>
          import('@/views/pages/offline-merchant/OfflineMerchantDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.BANNER],
          authorizeActions: [ACTION.BANNER_CREATE]
        },
        children: []
      },
      {
        path: 'new',
        name: ROUTE_NAME.OFFLINE_MERCHANT_NEW,
        component: () =>
          import('@/views/pages/offline-merchant/OfflineMerchantNew.vue'),
        meta: {
          name: 'new',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.BANNER],
          authorizeActions: [ACTION.BANNER_CREATE]
        },
        children: []
      }
    ]
  },
  // Offline Merchant ::end

  // Recipe (Experience Cooking) ::start
  {
    path: '/experience-cooking',
    name: ROUTE_NAME.EXPERIENCE_COOKING,
    redirect: { name: ROUTE_NAME.EXPERIENCE_COOKING_LIST },
    component: () => import('@/views/pages/recipe/Recipe.vue'),
    meta: {
      name: 'experienceCooking',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.RECIPE]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.EXPERIENCE_COOKING_LIST,
        component: () => import('@/views/pages/recipe/RecipeList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.RECIPE],
          authorizeActions: [ACTION.RECIPE_READ]
        },
        children: []
      },
      {
        path: '/experience-cooking/details/:id/:menu',
        name: ROUTE_NAME.EXPERIENCE_COOKING_DETAILS,
        component: () => import('@/views/pages/recipe/RecipeDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.RECIPE],
          authorizeActions: [ACTION.RECIPE_READ, ACTION.RECIPE_UPDATE]
        },
        children: []
      },
      {
        path: 'all',
        name: ROUTE_NAME.EXPERIENCE_COOKING_ALL,
        component: () => import('@/views/pages/recipe/RecipeList.vue'),
        meta: {
          name: 'all',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.RECIPE],
          authorizeActions: [ACTION.RECIPE_READ, ACTION.RECIPE_UPDATE]
        },
        children: []
      },
      {
        path: 'trending',
        name: ROUTE_NAME.EXPERIENCE_COOKING_TRENDING,
        component: () => import('@/views/pages/recipe/RecipeTrendingList.vue'),
        meta: {
          name: 'trending',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.RECIPE],
          authorizeActions: [ACTION.RECIPE_READ, ACTION.RECIPE_UPDATE]
        },
        children: []
      },
      {
        path: 'bakery',
        name: ROUTE_NAME.EXPERIENCE_COOKING_BAKERY,
        component: () => import('@/views/pages/recipe/RecipeBakeryList.vue'),
        meta: {
          name: 'bakery',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.RECIPE],
          authorizeActions: [ACTION.RECIPE_READ, ACTION.RECIPE_UPDATE]
        },
        children: []
      },
      {
        path: 'quick&Easy',
        name: ROUTE_NAME.EXPERIENCE_COOKING_QUICK_EASY,
        component: () =>
          import('@/views/pages/recipe/RecipeQuickAndEasyList.vue'),
        meta: {
          name: 'quick&easy',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.RECIPE],
          authorizeActions: [ACTION.RECIPE_READ, ACTION.RECIPE_UPDATE]
        },
        children: []
      },
      {
        path: 'cook-like-and-expert',
        name: ROUTE_NAME.EXPERIENCE_COOKING_COOK_LIKE_AND_EXPERT,
        component: () =>
          import('@/views/pages/recipe/RecipeCookLikeAnExpertList.vue'),
        meta: {
          name: 'cooklikeandexpert',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.RECIPE],
          authorizeActions: [ACTION.RECIPE_READ, ACTION.RECIPE_UPDATE]
        },
        children: []
      }
    ]
  },
  // Recipe (Experience Cooking) ::end

  // Banner ::start
  {
    path: '/banners',
    name: ROUTE_NAME.BANNER,
    redirect: { name: ROUTE_NAME.BANNER_HOME_LIST },
    component: () => import('@/views/pages/banner/Banner.vue'),
    meta: {
      name: 'banner',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.BANNER]
    },
    children: [
      {
        path: 'home',
        name: ROUTE_NAME.BANNER_HOME,
        redirect: { name: ROUTE_NAME.BANNER_HOME_LIST },
        component: () =>
          import('@/views/pages/banner/banner-home/BannerHome.vue'),
        meta: {
          name: 'homeBanner',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.BANNER],
          authorizeActions: [ACTION.BANNER_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.BANNER_HOME_LIST,
            component: () =>
              import('@/views/pages/banner/banner-home/BannerHomeList.vue'),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.BANNER],
              authorizeActions: [ACTION.BANNER_READ, ACTION.BANNER_UPDATE]
            },
            children: []
          },
          {
            path: 'new',
            name: ROUTE_NAME.BANNER_HOME_NEW,
            component: () =>
              import('@/views/pages/banner/banner-home/BannerHomeNew.vue'),
            meta: {
              name: 'new',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.BANNER],
              authorizeActions: [ACTION.BANNER_CREATE]
            },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.BANNER_HOME_DETAILS,
            component: () =>
              import('@/views/pages/banner/banner-home/BannerHomeDetails.vue'),
            meta: {
              name: 'details',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.BANNER],
              authorizeActions: [ACTION.BANNER_READ, ACTION.BANNER_UPDATE]
            },
            children: []
          }
        ]
      },
      {
        path: 'discover',
        name: ROUTE_NAME.BANNER_DISCOVER,
        redirect: { name: ROUTE_NAME.BANNER_DISCOVER_LIST },
        component: () =>
          import('@/views/pages/banner/banner-discover/BannerDiscover.vue'),
        meta: {
          name: 'discoverBanner',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.BANNER],
          authorizeActions: [ACTION.BANNER_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.BANNER_DISCOVER_LIST,
            component: () =>
              import(
                '@/views/pages/banner/banner-discover/BannerDiscoverList.vue'
              ),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.BANNER],
              authorizeActions: [ACTION.BANNER_READ, ACTION.BANNER_UPDATE]
            },
            children: []
          },
          {
            path: 'new',
            name: ROUTE_NAME.BANNER_DISCOVER_NEW,
            component: () =>
              import(
                '@/views/pages/banner/banner-discover/BannerDiscoverNew.vue'
              ),
            meta: {
              name: 'new',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.BANNER],
              authorizeActions: [ACTION.BANNER_CREATE]
            },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.BANNER_DISCOVER_DETAILS,
            component: () =>
              import(
                '@/views/pages/banner/banner-discover/BannerDiscoverDetails.vue'
              ),
            meta: {
              name: 'details',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.BANNER],
              authorizeActions: [ACTION.BANNER_READ, ACTION.BANNER_UPDATE]
            },
            children: []
          }
        ]
      },
      {
        path: 'xStreet',
        name: ROUTE_NAME.BANNER_X_STREET,
        redirect: { name: ROUTE_NAME.BANNER_X_STREET_LIST },
        component: () =>
          import('@/views/pages/banner/banner-x-street/BannerXStreet.vue'),
        meta: {
          name: 'bulletinBanner',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.BANNER],
          authorizeActions: [ACTION.BANNER_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.BANNER_X_STREET_LIST,
            component: () =>
              import(
                '@/views/pages/banner/banner-x-street/BannerXStreetList.vue'
              ),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.BANNER],
              authorizeActions: [ACTION.BANNER_READ, ACTION.BANNER_UPDATE]
            },
            children: []
          },
          {
            path: 'new',
            name: ROUTE_NAME.BANNER_X_STREET_NEW,
            component: () =>
              import(
                '@/views/pages/banner/banner-x-street/BannerXStreetNew.vue'
              ),
            meta: {
              name: 'new',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.BANNER],
              authorizeActions: [ACTION.BANNER_CREATE]
            },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.BANNER_X_STREET_DETAILS,
            component: () =>
              import(
                '@/views/pages/banner/banner-x-street/BannerXStreetDetails.vue'
              ),
            meta: {
              name: 'details',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.BANNER],
              authorizeActions: [ACTION.BANNER_READ, ACTION.BANNER_UPDATE]
            },
            children: []
          }
        ]
      },
      {
        path: '/xstreet',
        name: ROUTE_NAME.XSTREET,
        redirect: { name: ROUTE_NAME.XSTREET_LIST },
        component: () => import('@/views/pages/xstreet/XStreet.vue'),
        meta: {
          name: 'xstreet',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.XSTREET]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.XSTREET_LIST,
            component: () => import('@/views/pages/xstreet/XStreetList.vue'),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.XSTREET],
              authorizeActions: [ACTION.XSTREET_READ]
            },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.XSTREET_DETAILS,
            component: () => import('@/views/pages/xstreet/XStreetDetails.vue'),
            meta: {
              name: 'details',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.XSTREET],
              authorizeActions: [ACTION.XSTREET_READ, ACTION.XSTREET_UPDATE]
            },
            children: []
          }
        ]
      }
    ]
  },
  // Banner ::end

  // Product (Pre-Order Food) ::start
  {
    path: '/pre-order-food',
    name: ROUTE_NAME.PRE_ORDER_FOOD,
    redirect: { name: ROUTE_NAME.PRE_ORDER_FOOD_LIST },
    component: () => import('@/views/pages/product/Product.vue'),
    meta: {
      name: 'preOrderFood',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.PRODUCT]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.PRE_ORDER_FOOD_LIST,
        component: () => import('@/views/pages/product/ProductList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.PRODUCT],
          authorizeActions: [ACTION.PRODUCT_READ]
        },
        children: []
      },
      {
        path: '/pre-order-food/details/:id/:menu',
        name: ROUTE_NAME.PRE_ORDER_FOOD_DETAILS,
        component: () => import('@/views/pages/product/ProductDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.PRODUCT],
          authorizeActions: [ACTION.PRODUCT_READ, ACTION.PRODUCT_UPDATE]
        },
        children: []
      },
      {
        path: 'all',
        name: ROUTE_NAME.PRE_ORDER_FOOD_ALL,
        component: () => import('@/views/pages/product/ProductList.vue'),
        meta: {
          name: 'all',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.PRODUCT],
          authorizeActions: [ACTION.PRODUCT_READ]
        },
        children: []
      },
      {
        path: 'trending',
        name: ROUTE_NAME.PRE_ORDER_FOOD_TRENDING,
        component: () =>
          import('@/views/pages/product/ProductTrendingList.vue'),
        meta: {
          name: 'trending',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.PRODUCT],
          authorizeActions: [ACTION.PRODUCT_READ]
        },
        children: []
      },
      {
        path: 'something-sweet',
        name: ROUTE_NAME.PRE_ORDER_FOOD_SOMETHING_SWEET,
        component: () =>
          import('@/views/pages/product/ProductSomethingSweetList.vue'),
        meta: {
          name: 'somethingsweet',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.PRODUCT],
          authorizeActions: [ACTION.PRODUCT_READ]
        },
        children: []
      },
      {
        path: 'asia',
        name: ROUTE_NAME.PRE_ORDER_FOOD_ASIA,
        component: () => import('@/views/pages/product/ProductAsiaList.vue'),
        meta: {
          name: 'asia',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.PRODUCT],
          authorizeActions: [ACTION.PRODUCT_READ]
        },
        children: []
      },
      {
        path: 'western',
        name: ROUTE_NAME.PRE_ORDER_FOOD_WESTERN,
        component: () => import('@/views/pages/product/ProductWesternList.vue'),
        meta: {
          name: 'western',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.PRODUCT],
          authorizeActions: [ACTION.PRODUCT_READ]
        },
        children: []
      }
    ]
  },
  // Product (Pre-Order Food) ::end

  // Platform Info ::start
  {
    path: '/platform-info',
    name: ROUTE_NAME.PLATFORM_INFO,
    component: () => import('@/views/pages/platform-info/PlatformInfo.vue'),
    meta: {
      name: 'platformInfo',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.PLATFORM_INFO],
      authorizeActions: [ACTION.PLATFORM_INFO_READ, ACTION.PLATFORM_INFO_UPDATE]
    },
    children: []
  },
  // Platform Info ::end

  // Review And Rating ::start
  {
    path: '/review-and-rating',
    name: ROUTE_NAME.REVIEW_AND_RATING,
    redirect: { name: ROUTE_NAME.REVIEW_AND_RATING_EXPERIENCE_COOKING_LIST },
    component: () =>
      import('@/views/pages/review-and-rating/ReviewAndRating.vue'),
    meta: {
      name: 'reviewAndRating',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: []
    },
    children: [
      {
        path: 'experience-cooking',
        name: ROUTE_NAME.REVIEW_AND_RATING_EXPERIENCE_COOKING,
        redirect: {
          name: ROUTE_NAME.REVIEW_AND_RATING_EXPERIENCE_COOKING_LIST
        },
        component: () =>
          import(
            '@/views/pages/review-and-rating/review-and-rating-recipe/ReviewAndRatingRecipe.vue'
          ),
        meta: {
          name: 'experienceCooking',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.RECIPE_REVIEW],
          authorizeActions: [ACTION.RECIPE_REVIEW_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.REVIEW_AND_RATING_EXPERIENCE_COOKING_LIST,
            component: () =>
              import(
                '@/views/pages/review-and-rating/review-and-rating-recipe/ReviewAndRatingRecipeList.vue'
              ),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.RECIPE_REVIEW],
              authorizeActions: [ACTION.RECIPE_REVIEW_READ]
            },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.REVIEW_AND_RATING_EXPERIENCE_COOKING_DETAILS,
            component: () =>
              import(
                '@/views/pages/review-and-rating/review-and-rating-recipe/ReviewAndRatingRecipeDetails.vue'
              ),
            meta: {
              name: 'details',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.RECIPE_REVIEW],
              authorizeActions: [
                ACTION.RECIPE_REVIEW_READ,
                ACTION.RECIPE_REVIEW_UPDATE
              ]
            },
            children: []
          }
        ]
      },
      {
        path: 'pre-order-food',
        name: ROUTE_NAME.REVIEW_AND_RATING_PRE_ORDER_FOOD,
        redirect: { name: ROUTE_NAME.REVIEW_AND_RATING_PRE_ORDER_FOOD_LIST },
        component: () =>
          import(
            '@/views/pages/review-and-rating/review-and-rating-product/ReviewAndRatingProduct.vue'
          ),
        meta: {
          name: 'preOrderFood',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.PRODUCT_REVIEW],
          authorizeActions: [ACTION.PRODUCT_REVIEW_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.REVIEW_AND_RATING_PRE_ORDER_FOOD_LIST,
            component: () =>
              import(
                '@/views/pages/review-and-rating/review-and-rating-product/ReviewAndRatingProductList.vue'
              ),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.PRODUCT_REVIEW],
              authorizeActions: [ACTION.PRODUCT_REVIEW_READ]
            },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.REVIEW_AND_RATING_PRE_ORDER_FOOD_DETAILS,
            component: () =>
              import(
                '@/views/pages/review-and-rating/review-and-rating-product/ReviewAndRatingProductDetails.vue'
              ),
            meta: {
              name: 'details',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.PRODUCT_REVIEW],
              authorizeActions: [
                ACTION.PRODUCT_REVIEW_READ,
                ACTION.PRODUCT_REVIEW_UPDATE
              ]
            },
            children: []
          }
        ]
      }
    ]
  },
  // Review And Rating ::end

  // Maintenance ::start
  {
    path: '/maintenance',
    name: ROUTE_NAME.MAINTENANCE,
    redirect: { name: ROUTE_NAME.MAINTENANCE_LIST },
    component: () => import('@/views/pages/maintenance/Maintenance.vue'),
    meta: {
      name: 'maintenance',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.MAINTENANCE_SCHEDULE]
    },
    children: [
      {
        path: '',
        name: ROUTE_NAME.MAINTENANCE_LIST,
        component: () =>
          import('@/views/pages/maintenance/MaintenanceList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.MAINTENANCE_SCHEDULE],
          authorizeActions: [ACTION.MAINTENANCE_SCHEDULE_READ]
        },
        children: []
      }
    ]
  },
  // Maintenance ::end

  // App Update ::start
  {
    path: '/app-update',
    name: ROUTE_NAME.APP_UPDATE,
    redirect: { name: ROUTE_NAME.APP_UPDATE_LIST },
    component: () => import('@/views/pages/app-update/AppUpdate.vue'),
    meta: {
      name: 'appUpdate',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.APP_INFO]
    },
    children: [
      {
        path: '',
        name: ROUTE_NAME.APP_UPDATE_LIST,
        component: () => import('@/views/pages/app-update/AppUpdateList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.APP_INFO],
          authorizeActions: [ACTION.APP_INFO_READ, ACTION.APP_INFO_UPDATE]
        },
        children: []
      }
    ]
  },
  // App Update ::end

  // Platform Operation Hour ::start
  {
    path: '/platform-operation-hour',
    name: ROUTE_NAME.PLATFORM_OPERATION_HOUR,
    component: () =>
      import('@/views/pages/platform-operation-hour/PlatformOperationHour.vue'),
    meta: {
      name: 'platformOperationHour',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.PLATFORM_OPERATION_HOUR],
      authorizeActions: [
        ACTION.PLATFORM_OPERATION_HOUR_CREATE,
        ACTION.PLATFORM_OPERATION_HOUR_READ,
        ACTION.PLATFORM_OPERATION_HOUR_UPDATE,
        ACTION.PLATFORM_OPERATION_HOUR_DELETE
      ]
    },
    children: []
  },
  // Platform Operation Hour ::end

  // Eco-Pack ::start
  {
    path: '/eco-pack-fulfil',
    name: ROUTE_NAME.ECOPACK,
    redirect: { name: ROUTE_NAME.ECOPACK_LIST },
    component: () => import('@/views/pages/ecopack/EcoPack.vue'),
    meta: {
      name: 'ecopack',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.ECOPACK]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.ECOPACK_LIST,
        component: () => import('@/views/pages/ecopack/EcoPackList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT
          // requiresAuth: true,
          // authorizeModules: [MODULE.ECOPACK],
          // authorizeActions: [ACTION.ECOPACK_READ, ACTION.ECOPACK_UPDATE]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.ECOPACK_DETAILS,
        component: () => import('@/views/pages/ecopack/EcoPackDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ECOPACK],
          authorizeActions: [ACTION.ECOPACK_READ, ACTION.ECOPACK_UPDATE]
        },
        children: []
      }
    ]
  },
  // Eco-Pack ::end

  // Orders ::start
  {
    path: '/orders',
    name: ROUTE_NAME.ORDERS,
    redirect: {
      name: ROUTE_NAME.ORDERS_LIST
    },
    component: () => import('@/views/pages/orders/Orders.vue'),
    meta: {
      name: 'orders',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.ORDERS]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.ORDERS_LIST,
        component: () => import('@/views/pages/orders/OrdersList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ORDERS],
          authorizeActions: [ACTION.ORDERS_READ]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.ORDERS_DETAILS,
        component: () => import('@/views/pages/orders/OrdersDetails.vue'),
        props: true,
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ORDERS],
          authorizeActions: [ACTION.ORDERS_READ, ACTION.ORDERS_UPDATE]
        },
        children: []
      },
      {
        path: 'all',
        name: ROUTE_NAME.ORDERS_ALL,
        component: () => import('@/views/pages/orders/OrdersList.vue'),
        meta: {
          name: 'all',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ORDERS],
          authorizeActions: [ACTION.ORDERS_READ]
        },
        children: []
      },
      {
        path: 'order-eco-pack-fulfil',
        name: ROUTE_NAME.ORDERS_ECO_PACK_FULFIL,
        component: () =>
          import('@/views/pages/orders/OrdersEcoPackFulfilList.vue'),
        meta: {
          name: 'ecopackfulfil',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ORDERS],
          authorizeActions: [ACTION.ORDERS_READ]
        },
        children: []
      },
      {
        path: 'XChefs',
        name: ROUTE_NAME.ORDERS_XCHEFS,
        component: () => import('@/views/pages/orders/OrdersXChefsList.vue'),
        meta: {
          name: 'xChefs',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ORDERS],
          authorizeActions: [ACTION.ORDERS_READ]
        },
        children: []
      }
    ]
  },
  // Orders ::end

  // Finance Reports ::start
  {
    path: '/transactions/finance-reports',
    name: ROUTE_NAME.FINANCE_REPORTS,
    component: () => import('@/views/pages/reports/FinanceReports.vue'),
    meta: {
      name: 'transactions',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.ORDERS],
      authorizeActions: [ACTION.ORDERS_READ]
    },
    children: []
  },
  // Finance Reports ::end

  // home Page Button ::start
  {
    path: '/home-page-button',
    name: ROUTE_NAME.HOME_PAGE_BUTTON,
    redirect: { name: ROUTE_NAME.HOME_PAGE_BUTTON },
    component: () =>
      import('@/views/pages/home-page-button/homePageButton.vue'),
    meta: {
      name: 'homePageButton',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.BANNER_HOME]
    },
    children: [
      {
        path: 'home-experience-cooking',
        name: ROUTE_NAME.HOME_PAGE_EXPERIENCEE,
        component: () =>
          import('@/views/pages/home-page-button/experienceCooking.vue'),
        meta: {
          name: 'home-experience-cooking',
          layout: GLOBAL.LAYOUT,
          requiresAuth: false,
          authorizeModules: [MODULE.BANNER],
          authorizeActions: [ACTION.BANNER_READ]
        }
      },
      {
        path: 'home-pre-order-food',
        name: ROUTE_NAME.HOME_PAGE_PREORDER,
        component: () =>
          import('@/views/pages/home-page-button/preOrderFood.vue'),
        meta: {
          name: 'home-pre-order-food',
          layout: GLOBAL.LAYOUT,
          requiresAuth: false,
          authorizeModules: [MODULE.BANNER],
          authorizeActions: [ACTION.BANNER_READ]
        }
      },
      {
        path: 'home-dine-in',
        name: ROUTE_NAME.HOME_DINE_IN,
        component: () => import('@/views/pages/home-page-button/dineIn.vue'),
        meta: {
          name: 'home-dine-in',
          layout: GLOBAL.LAYOUT,
          requiresAuth: false,
          authorizeModules: [MODULE.BANNER],
          authorizeActions: [ACTION.BANNER_READ]
        }
      }
      // Amend By Jira Ticket COOKX-1663 :Start
      // {
      //   path: 'home-become-a-creator',
      //   name: ROUTE_NAME.HOME_PAGE_CREATOR,
      //   component: () =>
      //     import('@/views/pages/home-page-button/becomeACreator.vue'),
      //   meta: {
      //     name: 'become-a-creator',
      //     layout: GLOBAL.LAYOUT,
      //     requiresAuth: false,
      //     authorizeModules: [MODULE.BANNER],
      //     authorizeActions: [ACTION.BANNER_READ]
      //   }
      // },
      // {
      //   path: 'home-start-selling',
      //   name: ROUTE_NAME.HOME_PAGE_SELLING,
      //   component: () =>
      //     import('@/views/pages/home-page-button/startSelling.vue'),
      //   meta: {
      //     name: 'start-selling',
      //     layout: GLOBAL.LAYOUT,
      //     requiresAuth: false,
      //     authorizeModules: [MODULE.BANNER],
      //     authorizeActions: [ACTION.BANNER_READ]
      //   }
      // },
      // {
      //   path: 'home-start-buying',
      //   name: ROUTE_NAME.HOME_PAGE_BUYING,
      //   component: () =>
      //     import('@/views/pages/home-page-button/startBuying.vue'),
      //   meta: {
      //     name: 'start-buying',
      //     layout: GLOBAL.LAYOUT,
      //     requiresAuth: false,
      //     authorizeModules: [MODULE.BANNER],
      //     authorizeActions: [ACTION.BANNER_READ]
      //   }
      // }
      // Amend By Jira Ticket COOKX-1663 :End
    ]
  },
  // home Page Button ::end

  // promotion ::start
  {
    path: '/promotion',
    name: ROUTE_NAME.PROMOTION,
    redirect: { name: ROUTE_NAME.PROMOTION_RECIPES_DELIVERY_LIST },
    component: () => import('@/views/pages/promotion/Promotion.vue'),
    meta: {
      name: 'promotion',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.ORDERS]
    },
    children: [
      {
        path: 'recipes-delivery',
        name: ROUTE_NAME.PROMOTION_RECIPES_DELIVERY_LIST,
        component: () =>
          import('@/views/pages/promotion/PromotionRecipesDeliveryList.vue'),
        meta: {
          name: 'recipes-delivery',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ORDERS],
          authorizeActions: [ACTION.ORDERS_READ]
        },
        children: []
      },
      {
        path: 'product-delivery',
        name: ROUTE_NAME.PROMOTION_PRODUCT_DELIVERY_LIST,
        component: () =>
          import('@/views/pages/promotion/PromotionProductDeliveryList.vue'),
        meta: {
          name: 'product-delivery',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ORDERS],
          authorizeActions: [ACTION.ORDERS_READ]
        },
        children: []
      }
    ]
  },
  // promotion ::end

  // Referral Code ::start
  {
    path: '/referral-code',
    name: ROUTE_NAME.REFERRAL_CODE,
    redirect: { name: ROUTE_NAME.REFERRAL_CODE_LIST },
    component: () => import('@/views/pages/referral-code/ReferralCode.vue'),
    meta: {
      name: 'referral-code',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.ORDERS]
    },
    children: [
      {
        path: 'referral-code-list',
        name: ROUTE_NAME.REFERRAL_CODE_LIST,
        component: () =>
          import('@/views/pages/referral-code/ReferralCodeList.vue'),
        meta: {
          name: 'referral-code-list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.REFERRAL_CODE],
          authorizeActions: [ACTION.REFERRAL_CODE_READ]
        },
        children: []
      },
      {
        path: 'referral-code-details/:id',
        name: ROUTE_NAME.REFERRAL_CODE_DETAILS,
        component: () =>
          import('@/views/pages/referral-code/ReferralCodeDetails.vue'),
        meta: {
          name: 'referral-code-details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.REFERRAL_CODE],
          authorizeActions: [ACTION.REFERRAL_CODE_READ]
        },
        children: []
      },
      {
        path: 'referral-code-create',
        name: ROUTE_NAME.REFERRAL_CODE_CREATE,
        component: () =>
          import('@/views/pages/referral-code/ReferralCodeCreate.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.REFERRAL_CODE],
          authorizeActions: [ACTION.REFERRAL_CODE_CREATE]
        }
      }
    ]
  },
  // Referral Code ::end

  // Voucher ::start
  {
    path: '/voucher',
    name: ROUTE_NAME.VOUCHER,
    redirect: { name: ROUTE_NAME.VOUCHER_LIST },
    component: () => import('@/views/pages/voucher/Voucher.vue'),
    meta: {
      name: 'voucher',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.VOUCHER]
    },
    children: [
      {
        path: 'voucher-list',
        name: ROUTE_NAME.VOUCHER_LIST,
        component: () => import('@/views/pages/voucher/VoucherList.vue'),
        meta: {
          name: 'voucher-list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.VOUCHER],
          authorizeActions: [ACTION.VOUCHER_READ]
        },
        children: []
      },
      {
        path: 'voucher-details/:id',
        name: ROUTE_NAME.VOUCHER_DETAILS,
        component: () => import('@/views/pages/voucher/VoucherDetails.vue'),
        meta: {
          name: 'voucher-details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.VOUCHER],
          authorizeActions: [ACTION.VOUCHER_READ]
        },
        children: []
      },
      {
        path: 'voucher-create',
        name: ROUTE_NAME.VOUCHER_CREATE,
        component: () => import('@/views/pages/voucher/VoucherCreate.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.VOUCHER],
          authorizeActions: [ACTION.VOUCHER_CREATE]
        }
      }
    ]
  },
  // Voucher ::end

  // Configuration ::start
  {
    path: '/configuration',
    name: ROUTE_NAME.CONFIGURATION,
    component: () => import('@/views/pages/configuration/configuration.vue'),
    meta: {
      name: 'configuration',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.CONFIGURATION],
      authorizeActions: [
        ACTION.CONFIGURATION_CREATE,
        ACTION.CONFIGURATION_READ,
        ACTION.CONFIGURATION_UPDATE,
        ACTION.CONFIGURATION_DELETE
      ]
    },
    children: []
  },
  // Configuration ::end

  // Studio ::start
  {
    path: '/studio',
    name: ROUTE_NAME.STUDIO,
    redirect: { name: ROUTE_NAME.STUDIO_BOOKING_LIST },
    component: () => import('@/views/pages/studio/Studio.vue'),
    meta: {
      name: 'studio',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.STUDIO]
    },
    children: [
      {
        path: 'studio-booking',
        name: ROUTE_NAME.STUDIO_BOOKING,
        redirect: { name: ROUTE_NAME.STUDIO_BOOKING_LIST },
        component: () => import('@/views/pages/studio/Studio.vue'),
        meta: {
          name: 'studio-booking',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.STUDIO],
          authorizeActions: [ACTION.STUDIO_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.STUDIO_BOOKING_LIST,
            component: () =>
              import('@/views/pages/studio/StudioBookingList.vue'),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.STUDIO],
              authorizeActions: [ACTION.STUDIO_READ, ACTION.STUDIO_UPDATE]
            },
            children: []
          },
          {
            path: 'create',
            name: ROUTE_NAME.STUDIO_BOOKING_CREATE,
            component: () =>
              import('@/views/pages/studio/StudioBookingCreate.vue'),
            meta: {
              name: 'details',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.STUDIO],
              authorizeActions: [ACTION.STUDIO_CREATE]
            },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.STUDIO_BOOKING_DETAILS,
            component: () =>
              import('@/views/pages/studio/StudioBookingDetails.vue'),
            meta: {
              name: 'details',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.STUDIO],
              authorizeActions: [ACTION.STUDIO_READ, ACTION.STUDIO_UPDATE]
            },
            children: []
          }
        ]
      },
      {
        path: 'studio-calendar',
        name: ROUTE_NAME.STUDIO_CALENDAR,
        component: () => import('@/views/pages/studio/StudioCalendar.vue'),
        meta: {
          name: 'studio-calendar',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ORDERS],
          authorizeActions: [ACTION.ORDERS_READ]
        },
        children: []
      }
    ]
  },
  // Studio ::end

  // Equipment ::start
  {
    path: '/equipment',
    name: ROUTE_NAME.EQUIPMENT,
    redirect: { name: ROUTE_NAME.EQUIPMENT_LIST },
    component: () => import('@/views/pages/equipment/Equipment.vue'),
    meta: {
      name: 'equipment',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.EQUIPMENTS]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.EQUIPMENT_LIST,
        component: () => import('@/views/pages/equipment/EquipmentList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.EQUIPMENTS],
          authorizeActions: [ACTION.EQUIPMENTS_READ, ACTION.EQUIPMENTS_UPDATE]
        },
        children: []
      },
      {
        path: 'create',
        name: ROUTE_NAME.EQUIPMENT_CREATE,
        component: () => import('@/views/pages/equipment/EquipmentCreate.vue'),
        meta: {
          name: 'create',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.EQUIPMENTS],
          authorizeActions: [ACTION.EQUIPMENTS_CREATE]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.EQUIPMENT_DETAILS,
        component: () => import('@/views/pages/equipment/EquipmentDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.EQUIPMENTS],
          authorizeActions: [ACTION.EQUIPMENTS_READ, ACTION.EQUIPMENTS_UPDATE]
        },
        children: []
      }
    ]
  },
  // Equipment ::end

  // Popular Search ::start
  {
    path: '/popular-search',
    name: ROUTE_NAME.POPULAR_SEARCH,
    redirect: { name: ROUTE_NAME.POPULAR_SEARCH_LIST },
    component: () => import('@/views/pages/popular-search/PopularSearch.vue'),
    meta: {
      name: 'popular-search',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.POPULAR_SEARCH]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.POPULAR_SEARCH_LIST,
        component: () =>
          import('@/views/pages/popular-search/PopularSearchList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.POPULAR_SEARCH],
          authorizeActions: [
            ACTION.POPULAR_SEARCH_READ,
            ACTION.POPULAR_SEARCH_UPDATE
          ]
        },
        children: []
      }
    ]
  },
  // PopularSearch ::end

  // Recipe Page ::start
  {
    path: '/recipe-page',
    name: ROUTE_NAME.RECIPE_PAGE,
    redirect: { name: ROUTE_NAME.RECIPE_PAGE_LIST },
    component: () => import('@/views/pages/recipe-page/RecipePage.vue'),
    meta: {
      name: 'recipe-page',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.RECIPE_PAGE]
    },
    children: [
      {
        path: 'bakery',
        name: ROUTE_NAME.RECIPE_PAGE_BAKERY,
        redirect: { name: ROUTE_NAME.RECIPE_PAGE_BAKERY_LIST },
        component: () =>
          import('@/views/pages/recipe-page/bakery/RecipePageBakery.vue'),
        meta: {
          name: 'recipe-page-bakery',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.RECIPE_PAGE],
          authorizeActions: [ACTION.RECIPE_PAGE_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.RECIPE_PAGE_BAKERY_LIST,
            component: () =>
              import(
                '@/views/pages/recipe-page/bakery/RecipePageBakeryList.vue'
              ),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.RECIPE_PAGE],
              authorizeActions: [
                ACTION.RECIPE_PAGE_READ,
                ACTION.RECIPE_PAGE_UPDATE
              ]
            },
            children: []
          },
          {
            path: 'create',
            name: ROUTE_NAME.RECIPE_PAGE_BAKERY_CREATE,
            component: () =>
              import(
                '@/views/pages/recipe-page/bakery/RecipePageBakeryCreate.vue'
              ),
            meta: {
              name: 'create',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.RECIPE_PAGE],
              authorizeActions: [ACTION.RECIPE_PAGE_CREATE]
            },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.RECIPE_PAGE_BAKERY_DETAILS,
            component: () =>
              import(
                '@/views/pages/recipe-page/bakery/RecipePageBakeryDetails.vue'
              ),
            meta: {
              name: 'details',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.RECIPE_PAGE],
              authorizeActions: [
                ACTION.RECIPE_PAGE_READ,
                ACTION.RECIPE_PAGE_UPDATE
              ]
            },
            children: []
          }
        ]
      },
      {
        path: 'asian',
        name: ROUTE_NAME.RECIPE_PAGE_ASIAN,
        redirect: { name: ROUTE_NAME.RECIPE_PAGE_ASIAN_LIST },
        component: () =>
          import('@/views/pages/recipe-page/asian/RecipePageAsian.vue'),
        meta: {
          name: 'recipe-page-asian',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.RECIPE_PAGE],
          authorizeActions: [ACTION.RECIPE_PAGE_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.RECIPE_PAGE_ASIAN_LIST,
            component: () =>
              import('@/views/pages/recipe-page/asian/RecipePageAsianList.vue'),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.RECIPE_PAGE],
              authorizeActions: [
                ACTION.RECIPE_PAGE_READ,
                ACTION.RECIPE_PAGE_UPDATE
              ]
            },
            children: []
          },
          {
            path: 'create',
            name: ROUTE_NAME.RECIPE_PAGE_ASIAN_CREATE,
            component: () =>
              import(
                '@/views/pages/recipe-page/asian/RecipePageAsianCreate.vue'
              ),
            meta: {
              name: 'create',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.RECIPE_PAGE],
              authorizeActions: [ACTION.RECIPE_PAGE_CREATE]
            },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.RECIPE_PAGE_ASIAN_DETAILS,
            component: () =>
              import(
                '@/views/pages/recipe-page/asian/RecipePageAsianDetails.vue'
              ),
            meta: {
              name: 'details',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.RECIPE_PAGE],
              authorizeActions: [
                ACTION.RECIPE_PAGE_READ,
                ACTION.RECIPE_PAGE_UPDATE
              ]
            },
            children: []
          }
        ]
      },
      {
        path: 'western',
        name: ROUTE_NAME.RECIPE_PAGE_WESTERN,
        redirect: { name: ROUTE_NAME.RECIPE_PAGE_WESTERN_LIST },
        component: () =>
          import('@/views/pages/recipe-page/western/RecipePageWestern.vue'),
        meta: {
          name: 'recipe-page-western',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.RECIPE_PAGE],
          authorizeActions: [ACTION.RECIPE_PAGE_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.RECIPE_PAGE_WESTERN_LIST,
            component: () =>
              import(
                '@/views/pages/recipe-page/western/RecipePageWesternList.vue'
              ),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.RECIPE_PAGE],
              authorizeActions: [
                ACTION.RECIPE_PAGE_READ,
                ACTION.RECIPE_PAGE_UPDATE
              ]
            },
            children: []
          },
          {
            path: 'create',
            name: ROUTE_NAME.RECIPE_PAGE_WESTERN_CREATE,
            component: () =>
              import(
                '@/views/pages/recipe-page/western/RecipePageWesternCreate.vue'
              ),
            meta: {
              name: 'create',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.RECIPE_PAGE],
              authorizeActions: [ACTION.RECIPE_PAGE_CREATE]
            },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.RECIPE_PAGE_WESTERN_DETAILS,
            component: () =>
              import(
                '@/views/pages/recipe-page/western/RecipePageWesternDetails.vue'
              ),
            meta: {
              name: 'details',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.RECIPE_PAGE],
              authorizeActions: [
                ACTION.RECIPE_PAGE_READ,
                ACTION.RECIPE_PAGE_UPDATE
              ]
            },
            children: []
          }
        ]
      }
    ]
  },
  // Recipe Page ::end

  // Off Day Setting ::start
  {
    path: '/off-day-setting',
    name: ROUTE_NAME.OFF_DAY_SETTING,
    redirect: { name: ROUTE_NAME.OFF_DAY_SETTING_LIST },
    component: () => import('@/views/pages/off-day-setting/OffDaySetting.vue'),
    meta: {
      name: 'off-day-setting',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.OFF_DAY_SETTING]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.OFF_DAY_SETTING_LIST,
        component: () =>
          import('@/views/pages/off-day-setting/OffDaySettingList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.OFF_DAY_SETTING],
          authorizeActions: [
            ACTION.OFF_DAY_SETTING_READ,
            ACTION.OFF_DAY_SETTING_UPDATE
          ]
        },
        children: []
      },
      {
        path: 'create',
        name: ROUTE_NAME.OFF_DAY_SETTING_CREATE,
        component: () =>
          import('@/views/pages/off-day-setting/OffDaySettingCreate.vue'),
        meta: {
          name: 'create',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.OFF_DAY_SETTING],
          authorizeActions: [ACTION.OFF_DAY_SETTING_CREATE]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.OFF_DAY_SETTING_DETAILS,
        component: () =>
          import('@/views/pages/off-day-setting/OffDaySettingDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.OFF_DAY_SETTING],
          authorizeActions: [
            ACTION.OFF_DAY_SETTING_READ,
            ACTION.OFF_DAY_SETTING_UPDATE
          ]
        },
        children: []
      }
    ]
  },
  // Off Day Setting ::end

  // Merchant Grouping ::start
  {
    path: '/merchant-grouping',
    name: ROUTE_NAME.MERCHANT_GROUPING,
    redirect: { name: ROUTE_NAME.MERCHANT_GROUPING_LIST },
    component: () =>
      import('@/views/pages/merchant-grouping/MerchantGrouping.vue'),
    meta: {
      name: 'merchant-grouping',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.MERCHANT_GROUPING]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.MERCHANT_GROUPING_LIST,
        component: () =>
          import('@/views/pages/merchant-grouping/MerchantGroupingList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.MERCHANT_GROUPING],
          authorizeActions: [
            ACTION.MERCHANT_GROUPING_READ,
            ACTION.MERCHANT_GROUPING_UPDATE
          ]
        },
        children: []
      },
      {
        path: 'create',
        name: ROUTE_NAME.MERCHANT_GROUPING_CREATE,
        component: () =>
          import('@/views/pages/merchant-grouping/MerchantGroupingCreate.vue'),
        meta: {
          name: 'create',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.MERCHANT_GROUPING],
          authorizeActions: [ACTION.MERCHANT_GROUPING_CREATE]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.MERCHANT_GROUPING_DETAILS,
        component: () =>
          import('@/views/pages/merchant-grouping/MerchantGroupingDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.MERCHANT_GROUPING],
          authorizeActions: [
            ACTION.MERCHANT_GROUPING_READ,
            ACTION.MERCHANT_GROUPING_UPDATE
          ]
        },
        children: []
      }
    ]
  },
  // Merchant Grouping ::end

  // All Videos ::start
  {
    path: '/all-videos',
    name: ROUTE_NAME.ALL_VIDEOS,
    redirect: { name: ROUTE_NAME.ALL_VIDEOS_LIST },
    component: () =>
      import('@/views/pages/mobile-application/all-videos/AllVideos.vue'),
    meta: {
      name: 'all-videos',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.MOBILE_APPLICATION]
    },
    children: [
      {
        path: 'videos-experience-cooking',
        name: ROUTE_NAME.ALL_VIDEOS_EXPERIENCE_COOKING,
        redirect: { name: ROUTE_NAME.ALL_VIDEOS_EXPERIENCE_COOKING_LIST },
        component: () =>
          import(
            '@/views/pages/mobile-application/all-videos/recipe/AllVideosRecipe.vue'
          ),
        meta: {
          name: 'videos-experience-cooking',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.MOBILE_APPLICATION],
          authorizeActions: [ACTION.MOBILE_APPLICATION_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.ALL_VIDEOS_EXPERIENCE_COOKING_LIST,
            component: () =>
              import(
                '@/views/pages/mobile-application/all-videos/recipe/AllVideosRecipeList.vue'
              ),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.MOBILE_APPLICATION],
              authorizeActions: [
                ACTION.MOBILE_APPLICATION_READ,
                ACTION.MOBILE_APPLICATION_UPDATE
              ]
            },
            children: []
          }
        ]
      },
      {
        path: 'videos-pre-order-food',
        name: ROUTE_NAME.ALL_VIDEOS_PRE_ORDER_FOOD,
        redirect: { name: ROUTE_NAME.ALL_VIDEOS_PRE_ORDER_FOOD_LIST },
        component: () =>
          import(
            '@/views/pages/mobile-application/all-videos/product/AllVideosProduct.vue'
          ),
        meta: {
          name: 'videos-pre-order-food',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.MOBILE_APPLICATION],
          authorizeActions: [ACTION.MOBILE_APPLICATION_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.ALL_VIDEOS_PRE_ORDER_FOOD_LIST,
            component: () =>
              import(
                '@/views/pages/mobile-application/all-videos/product/AllVideosProductList.vue'
              ),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.MOBILE_APPLICATION],
              authorizeActions: [
                ACTION.MOBILE_APPLICATION_READ,
                ACTION.MOBILE_APPLICATION_UPDATE
              ]
            },
            children: []
          }
        ]
      }
    ]
  },
  // All Videos ::end

  // Eco Pack ::start
  {
    path: '/eco-pack',
    name: ROUTE_NAME.ECO_PACK,
    redirect: { name: ROUTE_NAME.ECO_PACK_LIST },
    component: () =>
      import('@/views/pages/mobile-application/eco-pack/EcoPack.vue'),
    meta: {
      name: 'eco-pack',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.MOBILE_APPLICATION],
      authorizeActions: [ACTION.MOBILE_APPLICATION_READ]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.ECO_PACK_LIST,
        component: () =>
          import('@/views/pages/mobile-application/eco-pack/EcoPackList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.MOBILE_APPLICATION],
          authorizeActions: [
            ACTION.MOBILE_APPLICATION_READ,
            ACTION.MOBILE_APPLICATION_UPDATE
          ]
        },
        children: []
      }
    ]
  },
  // Eco Pack ::end

  // Merchant ::start
  {
    path: '/merchant',
    name: ROUTE_NAME.MERCHANT,
    redirect: { name: ROUTE_NAME.MERCHANT_LIST },
    component: () =>
      import('@/views/pages/mobile-application/merchant/Merchant.vue'),
    meta: {
      name: 'merchant',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.MOBILE_APPLICATION]
    },
    children: [
      {
        path: 'all',
        name: ROUTE_NAME.MERCHANT_ALL,
        redirect: { name: ROUTE_NAME.MERCHANT_ALL_LIST },
        component: () =>
          import(
            '@/views/pages/mobile-application/merchant/all/MerchantAll.vue'
          ),
        meta: {
          name: 'all',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.MOBILE_APPLICATION],
          authorizeActions: [ACTION.MOBILE_APPLICATION_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.MERCHANT_ALL_LIST,
            component: () =>
              import(
                '@/views/pages/mobile-application/merchant/all/MerchantAllList.vue'
              ),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.MOBILE_APPLICATION],
              authorizeActions: [
                ACTION.MOBILE_APPLICATION_READ,
                ACTION.MOBILE_APPLICATION_UPDATE
              ]
            },
            children: []
          }
        ]
      },
      {
        path: 'dine-in',
        name: ROUTE_NAME.MERCHANT_DINE_IN,
        redirect: { name: ROUTE_NAME.MERCHANT_DINE_IN_LIST },
        component: () =>
          import(
            '@/views/pages/mobile-application/merchant/dine-in/MerchantDineIn.vue'
          ),
        meta: {
          name: 'dine-in',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.MOBILE_APPLICATION],
          authorizeActions: [ACTION.MOBILE_APPLICATION_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.MERCHANT_DINE_IN_LIST,
            component: () =>
              import(
                '@/views/pages/mobile-application/merchant/dine-in/MerchantDineInList.vue'
              ),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.MOBILE_APPLICATION],
              authorizeActions: [
                ACTION.MOBILE_APPLICATION_READ,
                ACTION.MOBILE_APPLICATION_UPDATE
              ]
            },
            children: []
          }
        ]
      },
      {
        path: 'online',
        name: ROUTE_NAME.MERCHANT_ONLINE,
        redirect: { name: ROUTE_NAME.MERCHANT_ONLINE_LIST },
        component: () =>
          import(
            '@/views/pages/mobile-application/merchant/online/MerchantOnline.vue'
          ),
        meta: {
          name: 'online',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.MOBILE_APPLICATION],
          authorizeActions: [ACTION.MOBILE_APPLICATION_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.MERCHANT_ONLINE_LIST,
            component: () =>
              import(
                '@/views/pages/mobile-application/merchant/online/MerchantOnlineList.vue'
              ),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.MOBILE_APPLICATION],
              authorizeActions: [
                ACTION.MOBILE_APPLICATION_READ,
                ACTION.MOBILE_APPLICATION_UPDATE
              ]
            },
            children: []
          }
        ]
      }
    ]
  },
  // Merchant ::end

  // Error ::start
  {
    path: '/error',
    component: () => import('@/views/pages/error/Error.vue'),
    children: [
      {
        path: '403',
        name: ROUTE_NAME.ERROR_403,
        component: () => import('@/views/pages/error/403.vue'),
        meta: {
          name: '403',
          layout: GLOBAL.LAYOUT_BLANK,
          requiresAuth: false,
          authorizeModules: []
        }
      },
      {
        path: '404',
        name: ROUTE_NAME.ERROR_404,
        component: () => import('@/views/pages/error/404.vue'),
        meta: {
          name: '404',
          layout: GLOBAL.LAYOUT_BLANK,
          requiresAuth: false,
          authorizeModules: []
        }
      }
    ]
  },
  // Error ::end

  // Login (/) ::start
  {
    path: '/',
    redirect: { name: ROUTE_NAME.LOGIN }
  },
  // Login (/)::end

  // Error 404 ::start
  {
    path: '*',
    redirect: { name: ROUTE_NAME.ERROR_404 }
  }
  // Error 404 ::end
];

export default routes;
