import { apiService } from '@/core/services';

export let listApiService = {
  getMerchantCreatorId,
  getDineInMerchantId,
  getMerchantOrCreatorBranch,
  getUserContactNumber,
  getEquipmentId,
  getMerchantName
};
async function getMerchantCreatorId() {
  let list = [];
  let result = null;
  let requestOptions = {
    params: {
      per_page: 9999
    }
  };
  await apiService()
    .get(`admin/merchant-vouchers/creator-lists`, requestOptions)
    .then((response) => {
      result = response.data;
      for (const [key, value] of Object.entries(result)) {
        list.push({ text: key, value: value });
      }
    });
  return list;
}

async function getDineInMerchantId() {
  let list = [];
  let result = null;
  let requestOptions = {
    params: {
      per_page: 9999
    }
  };
  await apiService()
    .get(`admin/merchant-vouchers/merchant-lists`, requestOptions)
    .then((response) => {
      result = response.data;
      for (const [key, value] of Object.entries(result)) {
        list.push({ text: key, value: value });
      }
    });
  return list;
}

async function getMerchantOrCreatorBranch(type, id) {
  let data = {};
  let list = [];
  let result = null;
  let requestOptions = {
    params: {
      per_page: 9999,
      id: id ? id : []
    }
  };

  if (type) {
    await apiService()
      .get(`admin/merchant-vouchers/${type}-branch-lists`, requestOptions)
      .then((response) => {
        result = response.data;
        for (const [key, value] of Object.entries(result)) {
          list.push({ text: key, value: value });
        }
        data = {
          list: list,
          allDineIn: response.all_dine_in
        };
      });
    return data;
  } else {
    return data;
  }
}

async function getUserContactNumber(value) {
  let list = [];
  let result = null;
  let requestOptions = {
    params: {
      contact_number: value
    }
  };

  await apiService()
    .get(`admin/search/user-contact`, requestOptions)
    .then((response) => {
      result = response.data;
      for (const [, /*key*/ value] of Object.entries(result)) {
        list.push({
          text: value.text,
          value: String(`{"id":${value.id}, "name":"${value.value.name}"}`),
          // value: { id: value.id, name: value.value.name },
          name: value.value.name
        });
      }
    });
  return list;
}

async function getEquipmentId() {
  let list = [];
  let result = null;
  let requestOptions = {
    params: {
      per_page: 9999
    }
  };
  await apiService()
    .get(`admin/equipments`, requestOptions)
    .then((response) => {
      result = response.data;
      result.forEach((x) => {
        list.push({
          text: x.name,
          value: x.id
        });
      });
    });
  return list;
}

function getMerchantName(val, type) {
  let list = [];
  let result = null;
  let requestOptions = {
    params: {
      name: val,
      category: type
    }
  };
  apiService()
    .get(`admin/search/merchants`, requestOptions)
    .then((response) => {
      result = response.data;

      result.map((x) => {
        list.push({
          text: x.name,
          value: { creatorId: x.creator_id, merchantId: x.merchant_id }
        });
      });
    });
  return list;
}
