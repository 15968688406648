import Vue from 'vue';
import Vuex from 'vuex';

/***** Improt API file *****/
import auth from './auth.module';
import htmlClass from './htmlclass.module';
import config from './config.module';
import breadcrumbs from './breadcrumbs.module';
import profile from './profile.module';
import loading from './loading.module';
import lookup from './lookup.module';
import constants from './constants.module';
import adminUser from './admin-user.module';
import role from './role.module';
import user from './user.module';
import creatorApplication from './creator-application.module';
import recipe from './recipe.module';
import banner from './banner.module';
import auditLog from './audit-log.module';
import xstreet from './xstreet.module';
import product from './product.module';
import platformInfo from './platform-info.module';
import recipeReview from './recipe-review.module';
import promotionRecipesDelivery from './promoiton-recipes-delivery.module';
import promotionProductsDelivery from './promoiton-products-delivery.module';
import productReview from './product-review.module';
import maintenanceSchedule from './maintenance-schedule.module';
import appInfo from './app-info.module';
import platformOperationHour from './platform-operation-hour.module';
import orders from './orders.module';
import referralCode from './referral-code.module';
import subscription from './subscription.module';
import offlineMerchant from './offline-merchant.module';
import voucher from './voucher.module';
import studio from './studio.module';
import equipment from './equipment.module';
import popularSearch from './popular-search.module';
import recipePage from './recipe-page.module';
import offDaySetting from './off-day-setting.module';
import merchantGrouping from './merchant-grouping.module';

// Mobile Application Module ::start
import mobileApplication from './mobile-application.module';
// Mobile Application Module ::end

// Configuration Module ::start
import configGoogleCalender from './configuration/config-google-calender.module';
import configWATITesting from './configuration/config-wati-testing.module';
import configNotifyCreator from './configuration/config-notify-creator.module';
import configNotifyStudio from './configuration/config-notify-studio.module';
import configEmailRecipient from './configuration/config-email-recipient.module';
// Configuration  Module ::end

/***** Improt API file *****/

//can refer Vuex document 'https://v3.vuex.vuejs.org/'
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    auth,
    adminUser,
    auditLog,
    appInfo,
    breadcrumbs,
    banner,
    config,
    configWATITesting,
    configGoogleCalender,
    configNotifyCreator,
    configNotifyStudio,
    configEmailRecipient,
    constants,
    creatorApplication,
    equipment,
    htmlClass,
    loading,
    lookup,
    maintenanceSchedule,
    merchantGrouping,
    voucher,
    orders,
    offDaySetting,
    offlineMerchant,
    profile,
    product,
    platformInfo,
    popularSearch,
    productReview,
    platformOperationHour,
    promotionRecipesDelivery,
    promotionProductsDelivery,
    role,
    recipe,
    recipePage,
    recipeReview,
    referralCode,
    subscription,
    studio,
    user,
    xstreet,
    mobileApplication
  }
});
