import { uiHelper } from '@/core/utils';
import { CRUDOrder, CRUDType } from '@/core/constants/enums';
import { PERMISSION_CONSTANTS, MODULE, LOCAL } from '@/core/constants';

export const permissionHelper = {
  setUserPermissions,
  getUserPermissions,
  removeUserPermissions,
  getConstantsPermissions,
  sortPermissionsByModule,
  permissionsSortedByModuleFeatureObject,
  existingPermissionsSortedByModuleFeatureObject
};

const module = [
  {
    name: 'member',
    children: [
      {
        name: 'profileDetails',
        key: MODULE.USER
      },
      {
        name: 'application',
        key: MODULE.CREATOR_APPLICATION
      }
    ]
  },
  {
    name: 'merchant',
    children: [
      {
        name: 'merchantGrouping',
        key: MODULE.MERCHANT_GROUPING
      },
      {
        name: 'offlineMerchant',
        key: MODULE.OFFLINE_MERCHANT
      },
      {
        name: 'popularSearch',
        key: MODULE.POPULAR_SEARCH
      }
    ]
  },
  {
    name: 'purchase',
    children: [
      {
        name: 'ecopack',
        key: MODULE.ECOPACK
      },
      {
        name: 'equipments',
        key: MODULE.EQUIPMENTS
      },
      {
        name: 'orders',
        key: MODULE.ORDERS
      }
    ]
  },
  {
    name: 'post',
    children: [
      {
        name: 'experienceCooking',
        key: MODULE.RECIPE
      },
      {
        name: 'preOrderFood',
        key: MODULE.PRODUCT
      },
      {
        name: 'bulletin',
        key: MODULE.XSTREET
      },
      {
        name: 'experienceCookingReview',
        key: MODULE.RECIPE_REVIEW
      },
      {
        name: 'preOrderFoodReview',
        key: MODULE.PRODUCT_REVIEW
      }
    ]
  },
  {
    name: 'promotion',
    children: [
      {
        name: 'delivery',
        key: MODULE.PROMOTION
      },
      {
        name: 'referralCode',
        key: MODULE.REFERRAL_CODE
      },
      {
        name: 'voucher',
        key: MODULE.VOUCHER
      }
    ]
  },
  {
    name: 'contentManagement',
    children: [
      {
        name: 'banner',
        key: MODULE.BANNER
      },
      {
        name: 'platformInfo',
        key: MODULE.PLATFORM_INFO
      }
    ]
  },
  {
    name: 'companyWebsite',
    children: [
      {
        name: 'studio',
        key: MODULE.STUDIO
      },
      {
        name: 'recipePage',
        key: MODULE.RECIPE_PAGE
      }
    ]
  },
  {
    name: 'mobileApplication',
    children: [
      {
        name: 'allVideos',
        key: MODULE.MOBILE_APPLICATION
      },
      {
        name: 'ecoPack',
        key: MODULE.MOBILE_APPLICATION
      },
      {
        name: 'merchant',
        key: MODULE.MOBILE_APPLICATION
      }
    ]
  },
  {
    name: 'adminUser',
    children: [
      {
        name: 'accountManagement',
        key: MODULE.ADMIN_USER
      },
      {
        name: 'rolePermission',
        key: MODULE.ROLE
      }
    ]
  },
  {
    name: 'transactions',
    children: [
      {
        name: 'financeReport',
        key: MODULE.FINANCE_REPORTS
      }
    ]
  },
  {
    name: 'settings',
    children: [
      {
        name: 'appUpdate',
        key: MODULE.APP_INFO
      },
      {
        name: 'maintenance',
        key: MODULE.MAINTENANCE_SCHEDULE
      },
      {
        name: 'platformOperationHour',
        key: MODULE.PLATFORM_OPERATION_HOUR
      },
      {
        name: 'offDaySetting',
        key: MODULE.OFF_DAY_SETTING
      },
      {
        name: 'configuration',
        key: MODULE.CONFIGURATION
      }
    ]
  },

  {
    name: 'others',
    children: [
      {
        name: 'auditLog',
        key: MODULE.AUDIT_LOG
      }
    ]
  }
];

function setUserPermissions(value) {
  let permissions = sortPermissionsByModule(value);
  uiHelper.setLocalStorage(
    PERMISSION_CONSTANTS.USER_PERMISSIONS,
    JSON.stringify(permissions)
  );
}

function getUserPermissions() {
  let permissions = uiHelper.getLocalStorage(
    PERMISSION_CONSTANTS.USER_PERMISSIONS
  );
  return JSON.parse(permissions);
}

function removeUserPermissions() {
  uiHelper.removeLocalStorage(PERMISSION_CONSTANTS.USER_PERMISSIONS);
}

function getConstantsPermissions() {
  let constants = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));
  constants.permissions = [
    ...constants.permissions
    // For test new permission, if Backend not update permission on API
    // 'test-permissions.read',
    // 'test-permissions.create',
    // 'test-permissions.update',
    // 'test-permissions.delete'
  ];
  return sortPermissionsByModule(constants.permissions);
}

function sortPermissionsByModule(permissions) {
  let permissionByModule = [];

  permissions.forEach((x) => {
    let name = x.split('.')[0];
    let actions = x.split('.')[1];
    if (permissionByModule.find((y) => y.name == name)) {
      permissionByModule
        .find((y) => y.name == name)
        .actions.push({
          name: actions,
          value: x
        });
    } else {
      permissionByModule.push({
        name: name,
        actions: [
          {
            name: actions,
            value: x
          }
        ]
      });
    }
  });

  return permissionByModule;
}

function permissionsSortedByModuleFeatureObject() {
  let userPermissions = getConstantsPermissions();
  let newUserPermissions = [];
  let list = [];
  let actions = [
    CRUDType.CREATE,
    CRUDType.READ,
    CRUDType.UPDATE,
    CRUDType.DELETE
  ];

  userPermissions.forEach((x) => {
    let newActionValue = [];

    x.actions.forEach((y) => {
      newActionValue.push({
        name: y.name,
        value: y.value,
        active: false
      });
    });

    //Sort the action by enum CRUDOrder
    newActionValue.sort(function (actionA, actionB) {
      if (
        CRUDOrder[actionA.name.toUpperCase()] >
        CRUDOrder[actionB.name.toUpperCase()]
      )
        return 1;
      if (
        CRUDOrder[actionA.name.toUpperCase()] <
        CRUDOrder[actionB.name.toUpperCase()]
      )
        return -1;
    });

    newUserPermissions.push({
      name: x.name,
      actions: newActionValue
    });
  });
  module.forEach((x) => {
    let children = [];
    let parentActions = [];

    actions.forEach((y) => {
      parentActions.push({
        active: false,
        indeterminate: false,
        name: y,
        key: x.name
      });
    });

    x.children.forEach((y) => {
      newUserPermissions.forEach((z) => {
        if (y.key == z.name) {
          children.push({
            name: y.name,
            key: y.key,
            actions: z.actions,
            active: false,
            indeterminate: false
          });
        }
      });
    });

    list.push({
      name: x.name,
      children: children,
      childrenVisible: false,
      actions: parentActions,
      active: false,
      indeterminate: false
    });
  });

  return {
    active: false,
    indeterminate: false,
    permissions: list
  };
}

function existingPermissionsSortedByModuleFeatureObject(list) {
  let blankObject = permissionsSortedByModuleFeatureObject();
  let actions = [
    CRUDType.CREATE,
    CRUDType.READ,
    CRUDType.UPDATE,
    CRUDType.DELETE
  ];
  let isAllChildrenActive = true;
  let hasChildrenActive = false;

  blankObject.permissions.forEach((x) => {
    let isAllActiveActions = [];
    let hasActiveActions = [];
    let hasActive = false;
    let isAllActionActive = true;

    x.children.forEach((y) => {
      let hasChildrenActionActive = false;
      let isAllChildrenActionActive = true;

      y.actions.forEach((z) => {
        if (list.includes(z.value)) {
          z.active = true;
          hasChildrenActionActive = true;
          hasActive = true;
        }
      });

      y.actions.forEach((z) => {
        if (!z.active) {
          isAllChildrenActionActive = false;
        }
      });

      y.active = isAllChildrenActionActive;
      y.indeterminate = isAllChildrenActionActive
        ? false
        : hasChildrenActionActive;
    });

    actions.forEach((a) => {
      let isAllChildrenActionActive = true;
      let hasChildrenActionActive = false;

      x.children.forEach((y) => {
        y.actions.forEach((z) => {
          if (z.name == a) {
            if (!z.active) {
              isAllChildrenActionActive = false;
            } else {
              hasChildrenActionActive = true;
            }
          }
        });
      });
      if (isAllChildrenActionActive) {
        isAllActiveActions.push(a);
      }

      if (hasChildrenActionActive) {
        hasActiveActions.push(a);
      }
    });

    x.actions.forEach((y) => {
      if (hasActiveActions.includes(y.name)) {
        y.indeterminate = true;
      }
    });

    x.actions.forEach((y) => {
      if (isAllActiveActions.includes(y.name)) {
        y.active = true;
        y.indeterminate = false;
      }
    });

    x.actions.forEach((y) => {
      if (!y.active) {
        isAllActionActive = false;
      }
    });

    x.active = isAllActionActive;
    x.indeterminate = isAllActionActive ? false : hasActive;

    x.children.forEach((y) => {
      if (y.active || y.indeterminate) {
        hasChildrenActive = true;
      } else {
        isAllChildrenActive = false;
      }
    });
  });

  blankObject.active = isAllChildrenActive;
  blankObject.indeterminate = isAllChildrenActive ? false : hasChildrenActive;

  return blankObject;
}
