import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import constantsDescription from '@/core/filters/constants-description.filter';
import customDescription from '@/core/filters/custom-description.filter';
import contactNumber from '@/core/filters/contact-number-format.filter';
import { USER } from '@/core/api';
import { apiHelper, i18nHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'user/';

/* Define Action Name */
const A_GET_USERS = 'getUsers';
const A_GET_USER = 'getUser';
const A_UPDATE_USER_ACTIVE_STATUS = 'updateUserActiveStatus';
const A_GET_USER_ADDRESS_BOOKS = 'getUserAddressBooks';
const A_GET_USER_ADDRESS_BOOK = 'getUserAddressBook';
const A_GET_USER_CREATOR_PROFILE = 'getUserCreatorProfile';
const A_GET_CREATOR_ADDRESS_BOOKS = 'getCreatorAddressBooks';
const A_GET_CREATOR_ADDRESS_BOOK = 'getCreatorAddressBook';
const A_GET_USER_REFEREES = 'getUserReferees';
const A_GET_USER_VOUCHERS = 'getUserVouchers';
const A_GET_USER_CREATOR_VOUCHERS = 'getUserCreatorVouchers';
const A_UPDATE_USER_CREATOR_PROFILE = 'updateUserCreatorProfile';
const A_UPDATE_USER_REFERRAL_CODE = 'updateUserReferralCode';

/* Define Reset State Action Name */
const A_INITIAL_GET_USERS_STATE = 'initialGetUsersState';
const A_INITIAL_GET_USER_STATE = 'initialGetUserState';
const A_INITIAL_UPDATE_USER_ACTIVE_STATUS = 'initialUpdateUserActiveStatus';
const A_INITIAL_GET_USER_ADDRESS_BOOKS = 'initialGetUserAddressBooks';
const A_INITIAL_GET_USER_ADDRESS_BOOK = 'initialGetUserAddressBook';
const A_INITIAL_GET_USER_CREATOR_PROFILE_STATE =
  'initialGetUserCreatorProfileState';
const A_INITIAL_GET_CREATOR_ADDRESS_BOOKS =
  'initialGetCreatorAddressBooksState';
const A_INITIAL_GET_CREATOR_ADDRESS_BOOK = 'initialGetCreatorAddressBookState';
const A_INITIAL_GET_USER_REFEREES = 'initialGetUserRefereesState';
const A_INITIAL_GET_USER_VOUCHERS_STATE = 'initialGetUserVouchersState';
const A_INITIAL_GET_USER_CREATOR_VOUCHERS_STATE =
  'initialGetUserCreatorVouchersState';
const A_INITIAL_UPDATE_USER_CREATOR_PROFILE = 'initialUpdateUserCreatorProfile';
const A_INITIAL_UPDATE_USER_REFERRAL_CODE = 'initialUpdateUserReferralCode';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_USERS = 'setGetUsers';
const M_GET_USER = 'setGetUser';
const M_UPDATE_USER_ACTIVE_STATUS = 'setUpdateUserActiveStatus';
const M_GET_USER_ADDRESS_BOOKS = 'setGetUserAddressBooks';
const M_GET_USER_ADDRESS_BOOK = 'setGetUserAddressBook';
const M_GET_USER_CREATOR_PROFILE = 'setGetUserCreatorProfile';
const M_GET_CREATOR_ADDRESS_BOOKS = 'setGetCreatorAddressBooks';
const M_GET_CREATOR_ADDRESS_BOOK = 'setGetCreatorAddressBook';
const M_GET_USER_REFEREES = 'setGetUserReferees';
const M_GET_USER_VOUCHERS = 'setGetUserVouchers';
const M_GET_USER_CREATOR_VOUCHERS = 'setGetUserCreatorVouchers';
const M_UPDATE_USER_CREATOR_PROFILE = 'setUpdateUserCreatorProfile';
const M_UPDATE_USER_REFERRAL_CODE = 'setUpdateUserReferralCode';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_USERS_STATE = 'setInitialGetUsersState';
const M_INITIAL_GET_USER_STATE = 'setInitialGetUserState';
const M_INITIAL_UPDATE_USER_ACTIVE_STATUS = 'setInitialUpdateUserActiveStatus';
const M_INITIAL_GET_USER_ADDRESS_BOOKS = 'setInitialGetUserAddressBooksState';
const M_INITIAL_GET_USER_ADDRESS_BOOK = 'setInitialGetUserAddressBookState';
const M_INITIAL_GET_USER_CREATOR_PROFILE_STATE =
  'setInitialGetUserCreatorProfileState';
const M_INITIAL_GET_CREATOR_ADDRESS_BOOKS =
  'setInitialGetCreatorAddressBooksState';
const M_INITIAL_GET_CREATOR_ADDRESS_BOOK =
  'setInitialGetCreatorAddressBookState';
const M_INITIAL_GET_USER_REFEREES = 'setInitialGetUserRefereesState';
const M_INITIAL_GET_USER_VOUCHERS_STATE = 'setInitialGetUserVouchersState';
const M_INITIAL_GET_USER_CREATOR_VOUCHERS_STATE =
  'setInitialGetUserCreatorVouchersState';
const M_INITIAL_UPDATE_USER_CREATOR_PROFILE =
  'setInitialUpdateUserCreatorProfile';
const M_INITIAL_UPDATE_USER_REFERRAL_CODE = 'setInitialUpdateUserReferralCode';

/* Define Export Name */
export const USER_GET_USERS = MODULE_NAME + A_GET_USERS;
export const USER_GET_USER = MODULE_NAME + A_GET_USER;
export const USER_UPDATE_USER_ACTIVE_STATUS =
  MODULE_NAME + A_UPDATE_USER_ACTIVE_STATUS;
export const USER_GET_USER_ADDRESS_BOOKS =
  MODULE_NAME + A_GET_USER_ADDRESS_BOOKS;
export const USER_GET_USER_ADDRESS_BOOK = MODULE_NAME + A_GET_USER_ADDRESS_BOOK;
export const USER_GET_USER_CREATOR_PROFILE =
  MODULE_NAME + A_GET_USER_CREATOR_PROFILE;
export const USER_GET_CREATOR_ADDRESS_BOOKS =
  MODULE_NAME + A_GET_CREATOR_ADDRESS_BOOKS;
export const USER_GET_CREATOR_ADDRESS_BOOK =
  MODULE_NAME + A_GET_CREATOR_ADDRESS_BOOK;
export const USER_GET_USER_REFEREES = MODULE_NAME + A_GET_USER_REFEREES;
export const USER_GET_USER_VOUCHERS = MODULE_NAME + A_GET_USER_VOUCHERS;
export const USER_GET_USER_CREATOR_VOUCHERS =
  MODULE_NAME + A_GET_USER_CREATOR_VOUCHERS;
export const USER_UPDATE_USER_CREATOR_PROFILE =
  MODULE_NAME + A_UPDATE_USER_CREATOR_PROFILE;
export const USER_UPDATE_USER_REFERRAL_CODE =
  MODULE_NAME + A_UPDATE_USER_REFERRAL_CODE;

/* Define Reset State Name */
export const USER_INITIAL_GET_USERS_STATE =
  MODULE_NAME + A_INITIAL_GET_USERS_STATE;
export const USER_INITIAL_GET_USER_STATE =
  MODULE_NAME + A_INITIAL_GET_USER_STATE;
export const USER_INITIAL_UPDATE_USER_ACTIVE_STATUS =
  MODULE_NAME + A_INITIAL_UPDATE_USER_ACTIVE_STATUS;
export const USER_INITIAL_GET_USER_ADDRESS_BOOKS =
  MODULE_NAME + A_INITIAL_GET_USER_ADDRESS_BOOKS;
export const USER_INITIAL_GET_USER_ADDRESS_BOOK =
  MODULE_NAME + A_INITIAL_GET_USER_ADDRESS_BOOK;
export const USER_INITIAL_GET_USER_CREATOR_PROFILE_STATE =
  MODULE_NAME + A_INITIAL_GET_USER_CREATOR_PROFILE_STATE;
export const USER_INITIAL_GET_CREATOR_ADDRESS_BOOKS =
  MODULE_NAME + A_INITIAL_GET_CREATOR_ADDRESS_BOOKS;
export const USER_INITIAL_GET_CREATOR_ADDRESS_BOOK =
  MODULE_NAME + A_INITIAL_GET_CREATOR_ADDRESS_BOOK;
export const USER_INITIAL_GET_USER_REFEREES =
  MODULE_NAME + A_INITIAL_GET_USER_REFEREES;
export const USER_INITIAL_GET_USER_VOUCHERS_STATE =
  MODULE_NAME + A_INITIAL_GET_USER_VOUCHERS_STATE;
export const USER_INITIAL_GET_USER_CREATOR_VOUCHERS_STATE =
  MODULE_NAME + A_INITIAL_GET_USER_CREATOR_VOUCHERS_STATE;
export const USER_INITIAL_UPDATE_USER_CREATOR_PROFILE =
  MODULE_NAME + A_INITIAL_UPDATE_USER_CREATOR_PROFILE;
export const USER_INITIAL_UPDATE_USER_REFERRAL_CODE =
  MODULE_NAME + A_INITIAL_UPDATE_USER_REFERRAL_CODE;

const state = {
  users: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  user: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateUserActive: {
    code: 0,
    complete: false,
    message: null
  },
  userAddressBooks: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  userAddressBook: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  userCreatorProfile: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  creatorAddressBooks: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  creatorAddressBook: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  userReferees: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  userVouchers: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  userCreatorVouchers: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  updateUserCreatorProfile: {
    code: 0,
    complete: false,
    message: null
  },
  updateUserReferralCode: {
    code: 0,
    complete: false,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_GET_USERS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    await USER.getUsers(data)
      .then((respone) => {
        let result = respone;
        commit(M_GET_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_USER]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.getUser(id)
      .then((respone) => {
        let result = respone;
        commit(M_GET_USER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_USER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_USER_ACTIVE_STATUS]({ dispatch, commit }, { id, isActive, type }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.updateUserActiveStatus(id, isActive, type)
      .then((respone) => {
        let result = respone;
        let typeApi =
          type == 'user'
            ? M_UPDATE_USER_ACTIVE_STATUS
            : M_UPDATE_USER_CREATOR_PROFILE;
        commit(typeApi, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        let typeApi =
          type == 'user'
            ? M_UPDATE_USER_ACTIVE_STATUS
            : M_UPDATE_USER_CREATOR_PROFILE;
        commit(typeApi, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_USER_ADDRESS_BOOKS]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.getUserAddressBooks(id, data)
      .then((respone) => {
        let result = respone;
        commit(M_GET_USER_ADDRESS_BOOKS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_USER_ADDRESS_BOOKS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_USER_ADDRESS_BOOK](
    { dispatch, commit },
    { userId, addressBookId }
  ) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    await USER.getUserAddressBook(userId, addressBookId)
      .then((respone) => {
        let result = respone;
        commit(M_GET_USER_ADDRESS_BOOK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_USER_ADDRESS_BOOK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_USER_CREATOR_PROFILE]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.getUserCreatorProfile(id)
      .then((respone) => {
        let result = respone;
        commit(M_GET_USER_CREATOR_PROFILE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_USER_CREATOR_PROFILE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_CREATOR_ADDRESS_BOOKS]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.getCreatorAddressBooks(id, data)
      .then((respone) => {
        let result = respone;
        commit(M_GET_CREATOR_ADDRESS_BOOKS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_CREATOR_ADDRESS_BOOKS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_CREATOR_ADDRESS_BOOK]({ dispatch, commit }, { parentId, id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.getCreatorAddressBook(parentId, id)
      .then((respone) => {
        let result = respone;
        commit(M_GET_CREATOR_ADDRESS_BOOK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_CREATOR_ADDRESS_BOOK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_USER_REFEREES]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.getUserReferees(id, data)
      .then((response) => {
        let result = response;
        commit(M_GET_USER_REFEREES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_USER_REFEREES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_USER_VOUCHERS]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.getUserVouchers(id, data)
      .then((response) => {
        let result = response;
        commit(M_GET_USER_VOUCHERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_USER_VOUCHERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_USER_CREATOR_VOUCHERS]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.getUserCreatorVouchers(id, data)
      .then((response) => {
        let result = response;
        commit(M_GET_USER_CREATOR_VOUCHERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_USER_CREATOR_VOUCHERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_USER_CREATOR_PROFILE]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.updateUserCreatorProfile(id, data)
      .then((response) => {
        let result = response;
        commit(M_UPDATE_USER_CREATOR_PROFILE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_USER_CREATOR_PROFILE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_USER_REFERRAL_CODE]({ dispatch, commit }, { id, referralCode }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.updateUserReferralCode(id, referralCode)
      .then((respone) => {
        let result = respone;
        commit(M_UPDATE_USER_REFERRAL_CODE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_USER_REFERRAL_CODE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_USERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_USERS_STATE);
  },
  [A_INITIAL_GET_USER_STATE]({ commit }) {
    commit(M_INITIAL_GET_USER_STATE);
  },
  [A_INITIAL_UPDATE_USER_ACTIVE_STATUS]({ commit }) {
    commit(M_INITIAL_UPDATE_USER_ACTIVE_STATUS);
  },
  [A_INITIAL_GET_USER_ADDRESS_BOOKS]({ commit }) {
    commit(M_INITIAL_GET_USER_ADDRESS_BOOKS);
  },
  [A_INITIAL_GET_USER_ADDRESS_BOOK]({ commit }) {
    commit(M_INITIAL_GET_USER_ADDRESS_BOOK);
  },
  [A_INITIAL_GET_USER_CREATOR_PROFILE_STATE]({ commit }) {
    commit(M_INITIAL_GET_USER_CREATOR_PROFILE_STATE);
  },
  [A_INITIAL_GET_CREATOR_ADDRESS_BOOKS]({ commit }) {
    commit(M_INITIAL_GET_CREATOR_ADDRESS_BOOKS);
  },
  [A_INITIAL_GET_CREATOR_ADDRESS_BOOK]({ commit }) {
    commit(M_INITIAL_GET_CREATOR_ADDRESS_BOOK);
  },
  [A_INITIAL_GET_USER_REFEREES]({ commit }) {
    commit(M_INITIAL_GET_USER_REFEREES);
  },
  [A_INITIAL_GET_USER_VOUCHERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_USER_VOUCHERS_STATE);
  },
  [A_INITIAL_GET_USER_CREATOR_VOUCHERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_USER_CREATOR_VOUCHERS_STATE);
  },
  [A_INITIAL_UPDATE_USER_CREATOR_PROFILE]({ commit }) {
    commit(M_INITIAL_UPDATE_USER_CREATOR_PROFILE);
  },
  [A_INITIAL_UPDATE_USER_REFERRAL_CODE]({ commit }) {
    commit(M_INITIAL_UPDATE_USER_REFERRAL_CODE);
  }
};

// Mutations
const mutations = {
  [M_GET_USERS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      let users = [];

      d.forEach((x, index) => {
        users.push({
          no: index + 1,
          id: x.id,
          username: x.username ? x.username : '-',
          storeName: x.store_name ? x.store_name : '-',
          contactNumber: contactNumber(x.contact_number),
          registerPlatform: constantsDescription(
            'registerPlatform',
            x.register_platform
          ),
          platformUsing: constantsDescription(
            'platformUsing',
            x.platform_using
          ),
          isActive: x.is_active,
          isCreator: x.is_creator,
          createdAt: dateTimeFormat(x.created_at),
          lastLoginAt: dateTimeFormat(x.last_login_at),
          creatorId: x.creator_id,
          accountDeletedAt: dateTimeFormat(x.account_deleted_at)
        });
      });

      state.users = {
        code: result.code,
        complete: true,
        data: users,
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.users = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_USER](state, result) {
    if (result.code == 0) {
      let d = result.data;

      state.user = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          username: d.username,
          displayName: d.display_name ? d.display_name : '-',
          email: d.email,
          contactNumber: contactNumber(d.contact_number),
          phoneVerifiedAt: dateTimeFormat(d.phone_verified_at),
          isActive: d.is_active,
          isCreator: d.is_creator,
          createdAt: dateTimeFormat(d.created_at),
          lastLoginAt: dateTimeFormat(d.last_login_at),
          accountDeletedAt: dateTimeFormat(d.account_deleted_at),
          bio: d.bio ? d.bio.split(',') : '-',
          aboutMe: d.about_me ? d.about_me : '-',
          birthday: dateTimeFormat(d.birthday),
          profilePhotoUrl: d.profile_photo_url,
          profileBackgroundUrl: d.profile_background_url,
          creatorApplicationStatus: {
            value: d.creator_application_status,
            name: i18nHelper.getMessage(
              `enumCreatorApplicationStatus[0].${d.creator_application_status}`
            )
          },
          foodTypePreferences: constantsDescription(
            'foodType',
            d.food_type_preferences
          ),
          registerPlatform: constantsDescription(
            'registerPlatform',
            d.register_platform
          ),
          platformUsing: constantsDescription(
            'platformUsing',
            d.platform_using
          ),
          followingCount: d.following_count,
          recipeCount: {
            active: d.recipe_count,
            inactive: d.inactive_recipe_count
          },
          referrerId: d.referrer_id,
          referrerName: d.referrer_name,
          referralObj: {
            id: d.referrer_id,
            name: d.referrer_name ? d.referrer_name : '-'
          },
          referralCode: d.referral_code
        },
        message: null
      };
    } else {
      state.user = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_UPDATE_USER_ACTIVE_STATUS](state, result) {
    state.updateUserActive = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_GET_USER_ADDRESS_BOOKS](state, result) {
    if (result.code == 0) {
      let p = result.pagination;

      state.userAddressBooks = {
        code: result.code,
        complete: true,
        data: result.data.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            placeName: x.place_name,
            contactName: x.contact_name,
            contactNumber: contactNumber(x.contact_number),
            address:
              (x.note_to_rider ? `${x.note_to_rider}, ` : '') +
              x.address +
              ', ' +
              x.postcode +
              ', ' +
              x.city +
              ', ' +
              x.state,
            postcode: x.postcode,
            city: x.city,
            state: x.state,
            isDefault: x.is_default,
            noteToRider: x.note_to_rider,
            createdAt: dateTimeFormat(x.created_at),
            updatedAt: dateTimeFormat(x.updated_at)
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.userAddressBooks = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_USER_ADDRESS_BOOK](state, result) {
    if (result.code == 0) {
      let d = result.data;

      state.userAddressBook = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          placeName: d.place_name,
          contactName: d.contact_name,
          contactNumber: contactNumber(d.contact_number),
          address:
            d.address + ', ' + d.postcode + ', ' + d.city + ', ' + d.state,
          postcode: d.postcode,
          city: d.city,
          state: d.state,
          isDefault: d.is_default,
          noteToRider: d.note_to_rider,
          createdAt: dateTimeFormat(d.created_at),
          updatedAt: dateTimeFormat(d.updated_at)
        },
        message: null
      };
    } else {
      state.userAddressBooks = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_USER_CREATOR_PROFILE](state, result) {
    if (result.code == 0) {
      let d = result.data;
      let documents = [];
      d.documents.forEach((x) => {
        let type = x.original_url.split('.');
        documents.push({
          uuid: x.uuid,
          originalUrl: x.original_url,
          documentType: x.document_type,
          documentTypeName: i18nHelper.getMessage(
            `enumCreatorApplicationDocumentType.${x.document_type}`
          ),
          fileType: type[type.length - 1].toLowerCase()
        });
      });

      //Sort the documents by document type
      documents.sort(function (docA, docB) {
        if (docA.documentType > docB.documentType) return 1;
        if (docA.documentType < docB.documentType) return -1;
      });

      state.userCreatorProfile = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          userId: d.user_id,
          username: d.username,
          storeName: d.store_name,
          creatorType: d.creator_type,
          creatorPhotoUrl: d.creator_photo_url,
          creatorName: d.creator_name ? d.creator_name : '-',
          creatorBackgroundUrl: d.creator_background_url,
          hashtag: d.hashtag ? d.hashtag.split(',') : '-',
          isActive: d.creator_is_active,
          submittedAt: dateTimeFormat(d.submitted_at),
          approvedAt: dateTimeFormat(d.approved_at),
          rejectedAt: dateTimeFormat(d.rejected_at),
          createdAt: dateTimeFormat(d.created_at),
          updatedAt: dateTimeFormat(d.updated_at),
          businessUrl: d.business_url,
          documents: documents,
          followerCount: d.follower_count,
          recipeCount: {
            active: d.recipe_count,
            inactive: d.inactive_recipe_count
          },
          productCount: {
            active: d.product_count,
            inactive: d.inactive_product_count
          },
          taxRate: d.tax_rate * 100,
          serviceType: constantsDescription('serviceType', d.service_type),
          serviceTypeValue: d.service_type,
          description: d.description ? d.description : '-',
          pinVerification: d.voucher_pin_verification,
          cuisineType: customDescription('cuisineType', d.cuisine_type),
          cuisineTypeValue: d.cuisine_type ? d.cuisine_type : [],
          websiteLink: d.website_link ? d.website_link : '-'
        },
        message: null
      };
    } else {
      state.userCreatorProfile = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_CREATOR_ADDRESS_BOOKS](state, result) {
    if (result.code == 0) {
      let p = result.pagination;

      state.creatorAddressBooks = {
        code: result.code,
        complete: true,
        data: result.data.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            creatorId: x.creator_id,
            placeName: x.place_name,
            contactNumber: contactNumber(x.contact_number),
            contactEmail: x.contact_email,
            address:
              (x.remark ? `${x.remark}, ` : '') +
              x.address +
              ', ' +
              x.postcode +
              ', ' +
              x.city +
              ', ' +
              x.state,
            postcode: x.postcode,
            city: x.city,
            state: x.state,
            remark: x.remark,
            createdAt: dateTimeFormat(x.created_at),
            updatedAt: dateTimeFormat(x.updated_at),
            businessHours: x.business_hours.map((y) => {
              return {
                day: y.day,
                operationHour: y.operation_hour.map((z) => {
                  return {
                    startTime: dateTimeFormat(z.start_time),
                    endTime: dateTimeFormat(z.end_time)
                  };
                })
              };
            })
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.creatorAddressBooks = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_CREATOR_ADDRESS_BOOK](state, result) {
    if (result.code == 0) {
      let d = result.data;

      state.creatorAddressBook = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          creatorId: d.creator_id,
          placeName: d.place_name,
          contactNumber: contactNumber(d.contact_number),
          contactEmail: d.contact_email,
          address: d.address,
          postcode: d.postcode,
          city: d.city,
          state: d.state,
          remark: d.remark,
          createdAt: dateTimeFormat(d.created_at),
          updatedAt: dateTimeFormat(d.updated_at),
          businessHours: d.business_hours.map((y) => {
            return {
              day: y.day,
              operationHour: y.operation_hour.map((z) => {
                return {
                  startTime: dateTimeFormat(z.start_time),
                  endTime: dateTimeFormat(z.end_time)
                };
              })
            };
          })
        },
        message: null
      };
    } else {
      state.creatorAddressBook = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_USER_REFEREES](state, result) {
    if (result.code == 0) {
      let p = result.pagination;

      state.userReferees = {
        code: result.code,
        complete: true,
        data: result.data.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            userId: x.user_id,
            username: x.username,
            userObj: {
              id: x.user_id,
              name: x.username
            },
            createdAt: dateTimeFormat(x.created_at)
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.userReferees = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_USER_VOUCHERS](state, result) {
    if (result.code === 0) {
      let p = result.pagination;

      state.userVouchers = {
        code: result.code,
        complete: true,
        data: result.data.map((x, index) => {
          return {
            no: index + 1,
            voucherId: x.voucher_id,
            name: x.name,
            value: x.value,
            minimumSpending: x.minimum_spending,
            usageLimit: x.usage_limit,
            numberOfUses: x.number_of_uses,
            awardedAt: dateTimeFormat(x.awarded_at),
            isActive: x.is_active,
            availableFrom: dateTimeFormat(x.available_from),
            availableTo: dateTimeFormat(x.available_to)
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.userVouchers = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_USER_CREATOR_VOUCHERS](state, result) {
    if (result.code === 0) {
      let p = result.pagination;

      state.userCreatorVouchers = {
        code: result.code,
        complete: true,
        data: result.data.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            eventName: x.voucher_merchant.event_name,
            voucherTitle: { id: x.id, name: x.title },
            voucherType: constantsDescription('serviceType', x.voucher_type),
            quantity: x.voucher_merchant.quantity,
            minimumSpending: x.minimum_spending,
            status: x.voucher_merchant.status,
            totalClaim: x.total_claim,
            totalRedemption: x.total_redeem,
            availableFrom: dateTimeFormat(x.voucher_merchant.available_from),
            availableTo: dateTimeFormat(x.voucher_merchant.available_to)
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.userCreatorVouchers = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_UPDATE_USER_CREATOR_PROFILE](state, result) {
    state.updateUserCreatorProfile = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_USERS_STATE](state) {
    state.users = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_USER_STATE](state) {
    state.user = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_USER_ACTIVE_STATUS](state) {
    state.updateUserActive = {
      code: 0,
      complete: false,
      message: null
    };
  },
  [M_INITIAL_GET_USER_ADDRESS_BOOKS](state) {
    state.userAddressBooks = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_USER_ADDRESS_BOOK](state) {
    state.userAddressBook = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_USER_CREATOR_PROFILE_STATE](state) {
    state.userCreatorProfile = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_CREATOR_ADDRESS_BOOKS](state) {
    state.creatorAddressBooks = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_CREATOR_ADDRESS_BOOK](state) {
    state.creatorAddressBook = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_USER_REFEREES](state) {
    state.userReferees = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_USER_VOUCHERS_STATE](state) {
    state.userVouchers = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_USER_CREATOR_VOUCHERS_STATE](state) {
    state.userCreatorVouchers = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_UPDATE_USER_CREATOR_PROFILE](state) {
    state.updateUserCreatorProfile = {
      code: 0,
      complete: false,
      message: null
    };
  },
  [M_UPDATE_USER_REFERRAL_CODE](state, result) {
    state.updateUserReferralCode = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_UPDATE_USER_REFERRAL_CODE](state) {
    state.updateUserReferralCode = {
      code: 0,
      complete: false,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
