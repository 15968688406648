const ROUTE_NAME = Object.freeze({
  HOME: 'home',
  LOGIN: 'login',
  /* Account Management */
  ACCOUNT_MANAGEMENT: 'account-management',
  ACCOUNT_MANAGEMENT_LIST: 'account-management-list',
  ACCOUNT_MANAGEMENT_NEW: 'account-management-new',
  ACCOUNT_MANAGEMENT_DETAILS: 'account-management-details',

  /* Role Permission */
  ROLE_PERMISSION: 'role-permission',
  ROLE_PERMISSION_LIST: 'role-permission-list',
  ROLE_PERMISSION_NEW: 'role-permission-new',
  ROLE_PERMISSION_DETAILS: 'role-permission-details',

  /* Profile Details */
  PROFILE_DETAILS: 'profile-details',
  PROFILE_DETAILS_LIST: 'profile-details-list',
  PROFILE_DETAILS_DETAILS: 'profile-details-details',

  /* Application */
  APPLICATION: 'application',
  APPLICATION_LIST: 'application-list',
  APPLICATION_DETAILS: 'application-details',

  /* Member Subscription */
  SUBSCRIPTION: 'subscription',
  SUBSCRIPTION_LIST: 'subscription-list',

  /* Member Offline Merchant */
  OFFLINE_MERCHANT: 'offline-merchant',
  OFFLINE_MERCHANT_LIST: 'Offline-merchant-list',
  OFFLINE_MERCHANT_NEW: 'Offline-merchant-new',
  OFFLINE_MERCHANT_DETAILS: 'Offline-merchant-details',

  /* Experience Cooking */
  EXPERIENCE_COOKING: 'experience-cooking',
  EXPERIENCE_COOKING_LIST: 'experience-cooking-list',
  EXPERIENCE_COOKING_DETAILS: 'experience-cooking-details',
  EXPERIENCE_COOKING_ALL: 'experience-cooking-all',
  EXPERIENCE_COOKING_TRENDING: 'experience-cooking-trending',
  EXPERIENCE_COOKING_BAKERY: 'experience-cooking-bakery',
  EXPERIENCE_COOKING_QUICK_EASY: 'experience-cooking-quick_easy',
  EXPERIENCE_COOKING_COOK_LIKE_AND_EXPERT:
    'experience-cooking-cook-like-and-expert',

  /* Pre-order Food */
  PRE_ORDER_FOOD: 'pre-order-food',
  PRE_ORDER_FOOD_LIST: 'pre-order-food-list',
  PRE_ORDER_FOOD_DETAILS: 'pre-order-food-details',
  PRE_ORDER_FOOD_ALL: 'pre-order-food-all',
  PRE_ORDER_FOOD_TRENDING: 'pre-order-food-trending',
  PRE_ORDER_FOOD_SOMETHING_SWEET: 'pre-order-food-something-sweet',
  PRE_ORDER_FOOD_ASIA: 'pre-order-food-asia',
  PRE_ORDER_FOOD_WESTERN: 'pre-order-food-western',

  /* Banner */
  BANNER: 'banner',
  BANNER_HOME: 'banner-home',
  BANNER_HOME_LIST: 'banner-home-list',
  BANNER_HOME_NEW: 'banner-home-new',
  BANNER_HOME_DETAILS: 'banner-home-details',
  BANNER_DISCOVER: 'banner-discover',
  BANNER_DISCOVER_LIST: 'banner-discover-list',
  BANNER_DISCOVER_NEW: 'banner-discover-new',
  BANNER_DISCOVER_DETAILS: 'banner-discover-details',
  BANNER_X_STREET: 'banner-x-street',
  BANNER_X_STREET_LIST: 'banner-x-street-list',
  BANNER_X_STREET_NEW: 'banner-x-street-new',
  BANNER_X_STREET_DETAILS: 'banner-x-street-details',

  /* XStreet */
  XSTREET: 'xstreet',
  XSTREET_LIST: 'xstreet-list',
  XSTREET_DETAILS: 'xstreet-details',

  /* Platform Info */
  PLATFORM_INFO: 'platform-info',

  /* Review and Rating */
  REVIEW_AND_RATING: 'review-and-rating',
  REVIEW_AND_RATING_EXPERIENCE_COOKING: 'review-and-rating-experience-cooking',
  REVIEW_AND_RATING_EXPERIENCE_COOKING_LIST:
    'review-and-rating-experience-cooking-list',
  REVIEW_AND_RATING_EXPERIENCE_COOKING_DETAILS:
    'review-and-rating-experience-cooking-details',
  REVIEW_AND_RATING_PRE_ORDER_FOOD: 'review-and-rating-pre-order-food',
  REVIEW_AND_RATING_PRE_ORDER_FOOD_LIST:
    'review-and-rating-pre-order-food-list',
  REVIEW_AND_RATING_PRE_ORDER_FOOD_DETAILS:
    'review-and-rating-pre-order-food-details',

  /* Maintenance Schedule */
  MAINTENANCE: 'maintenance',
  MAINTENANCE_LIST: 'maintenance-list',

  /* App Update */
  APP_UPDATE: 'app-update',
  APP_UPDATE_LIST: 'app-update-list',

  /* Platform Operation Hour */
  PLATFORM_OPERATION_HOUR: 'platform-operation-hour',

  /* Order */
  ORDERS: 'orders',
  ORDERS_ALL: 'orders-all',
  ORDERS_ECO_PACK_FULFIL: 'orders-eco-pack-fulfil',
  ORDERS_XCHEFS: 'orders-xchefs',
  ORDERS_LIST: 'orders-list',
  ORDERS_DETAILS: 'orders-details',

  /* EcoPack*/
  ECOPACK: 'ecopack',
  ECOPACK_ALL: 'ecopack-all',
  ECOPACK_LIST: 'ecopack-list',
  ECOPACK_DETAILS: 'ecopack-details',

  /* Promotion*/
  PROMOTION: 'promotion',
  PROMOTION_DELIVERY: 'promotion-delivery',

  /* Referral Code*/
  REFERRAL_CODE: 'referral-code',
  REFERRAL_CODE_LIST: 'referral-code-list',
  REFERRAL_CODE_DETAILS: 'referral-code-details',
  REFERRAL_CODE_CREATE: 'referral-code-create',

  /* Voucher*/
  VOUCHER: 'voucher',
  VOUCHER_LIST: 'voucher-list',
  VOUCHER_DETAILS: 'voucher-details',
  VOUCHER_CREATE: 'voucher-create',

  /* Off Day Setting */
  CONFIGURATION: 'configuration',

  /* Studio Booking */
  STUDIO_BOOKING: 'studio-booking',
  STUDIO_BOOKING_LIST: 'studio-booking-list',
  STUDIO_BOOKING_DETAILS: 'studio-booking-details',
  STUDIO_BOOKING_CREATE: 'studio-booking-create',
  STUDIO_CALENDAR: 'studio-calendar',

  /* Equipment */
  EQUIPMENT: 'equipment',
  EQUIPMENT_LIST: 'equipment-list',
  EQUIPMENT_DETAILS: 'equipment-details',
  EQUIPMENT_CREATE: 'equipment-create',

  /* Equipment */
  POPULAR_SEARCH: 'popular-search',
  POPULAR_SEARCH_LIST: 'popular-search-list',
  POPULAR_SEARCH_CREATE: 'popular-search-create',

  /* Recipe Page */
  RECIPE_PAGE: 'recipe-page',
  RECIPE_PAGE_BAKERY: 'recipe-page-bakery',
  RECIPE_PAGE_BAKERY_LIST: 'recipe-page-bakery-list',
  RECIPE_PAGE_BAKERY_DETAILS: 'recipe-page-bakery-details',
  RECIPE_PAGE_BAKERY_CREATE: 'recipe-page-bakery-create',
  RECIPE_PAGE_ASIAN: 'recipe-page-asian',
  RECIPE_PAGE_ASIAN_LIST: 'recipe-page-asian-list',
  RECIPE_PAGE_ASIAN_DETAILS: 'recipe-page-asian-details',
  RECIPE_PAGE_ASIAN_CREATE: 'recipe-page-asian-create',
  RECIPE_PAGE_WESTERN: 'recipe-page-western',
  RECIPE_PAGE_WESTERN_LIST: 'recipe-page-western-list',
  RECIPE_PAGE_WESTERN_DETAILS: 'recipe-page-western-details',
  RECIPE_PAGE_WESTERN_CREATE: 'recipe-page-western-create',

  /* Off Day Settings */
  OFF_DAY_SETTING: 'off-day-setting',
  OFF_DAY_SETTING_LIST: 'off-day-setting-list',
  OFF_DAY_SETTING_DETAILS: 'off-day-setting-details',
  OFF_DAY_SETTING_CREATE: 'off-day-setting-create',

  /* Merchant Grouping */
  MERCHANT_GROUPING: 'merchant-grouping',
  MERCHANT_GROUPING_LIST: 'merchant-grouping-list',
  MERCHANT_GROUPING_DETAILS: 'merchant-grouping-details',
  MERCHANT_GROUPING_CREATE: 'merchant-grouping-create',

  /* All Videos */
  ALL_VIDEOS: 'all-videos',
  ALL_VIDEOS_EXPERIENCE_COOKING: 'all-videos-experience-cooking',
  ALL_VIDEOS_EXPERIENCE_COOKING_LIST: 'all-videos-experience-cooking-list',
  ALL_VIDEOS_PRE_ORDER_FOOD: 'all-videos-pre-order-food',
  ALL_VIDEOS_PRE_ORDER_FOOD_LIST: 'all-videos-pre-order-food-list',

  /* Eco Pack */
  ECO_PACK: 'eco-pack',
  ECO_PACK_LIST: 'eco-pack-list',

  /* Merchant */
  MERCHANT: 'merchant',
  MERCHANT_ALL: 'merchant-all',
  MERCHANT_ALL_LIST: 'merchant-all-list',
  MERCHANT_DINE_IN: 'merchant-dine-in',
  MERCHANT_DINE_IN_LIST: 'merchant-dine-in-list',
  MERCHANT_ONLINE: 'merchant-online',
  MERCHANT_ONLINE_LIST: 'merchant-online-list',

  /* Error */
  ERROR_403: '403',
  ERROR_404: '404'
});

export { ROUTE_NAME };
